import React, { useState, useEffect } from "react";
import axios from "axios";

const UserCounter = () => {
  const [count, setCount] = useState(0);

  // User Data
  useEffect(() => {
    const userCount = async () => {
      await axios.get("/users").then((response) => {
        setCount(response.data.length);
      });
    }
    userCount();

    return () => {
      setCount(0) 
    }
    
  }, []);

  return <div>{count}</div>;
};

export default UserCounter;
