import { useEffect, useState } from 'react'
import axios from 'axios'
import './IndexingForHome.css'
import { useTranslation } from 'react-i18next'

const IndexingForHome = () => {
  const { t } = useTranslation()
  const [postObject, setPostObject] = useState([])

  useEffect(() => {
    const getData = async () => {
      await axios.get('/index/').then(response => {
        setPostObject(response.data)
      })
    }
    getData()

    return () => {
      setPostObject([])
    }
  }, [])

  return (
    <>
      {postObject.length !== 0 ? (
        <>
          <div className='container'>
            <div className='archive-page'>
              <div className='archive-wrapper'>
                <div className='archive-title'>
                  <div className='border-contact-home' />
                  <h5>{t('homepage.indexingtitle')}</h5>
                  <div className='border-contact-home' />
                </div>
                <div className='indexingForHome'>
                  {postObject.map((value, index) => {
                    return (
                      <a key={index} href={value.indexUrl} target='_blank' rel='noreferrer'>
                        <img
                          variant='top'
                          src={value.imageUrl}
                          alt='card-img'
                          style={{ objectFit: 'contain' }}
                        />
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default IndexingForHome
