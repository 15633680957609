import axios from 'axios'
import { useEffect, useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './Indexing.css'
import { Helmet } from 'react-helmet'

const IndexList = () => {
  const [postObject, setPostObject] = useState([])

  useEffect(() => {
    const getData = async () => {
      await axios
        .get('/index/')
        .then(response => {
          setPostObject(response.data)
        })
        .catch(console.error)
    }
    getData()

    return () => {
      setPostObject([])
    }
  }, [])
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('index.title')}</title>
        <link rel='canonical' href='http://moambe.org.ge/index' />
        <meta name='google scholar, scopus' description='google scholar, scopus' charSet='utf-8' />
      </Helmet>
      <div className='archive-title' style={{ marginTop: '50px' }}>
        <div className='border-contact-home' />
        <h4>{t('index.title')}</h4>
        <div className='border-contact-home' />
      </div>
      <div className='indexing-container'>
        {postObject.map(value => {
          return (
            <div key={value.indexUrl} className='indexing-cards'>
              <Col style={{ width: '200px', margin: '25px 0px 25px 0px' }}>
                <div className='information'>
                  <Card>
                    <a href={value.indexUrl} target='_blank' rel='noreferrer'>
                      <Card.Img
                        variant='top'
                        src={value.imageUrl}
                        alt='card-img'
                        style={{ objectFit: 'contain' }}
                      />
                    </a>
                  </Card>
                </div>
              </Col>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default IndexList
