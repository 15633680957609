import { useState, useEffect } from 'react'
import axios from 'axios'
import './IndexList.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import { Container, Modal } from 'react-bootstrap'
import Grid from '@mui/material/Grid'

import MaterialTable from 'material-table'
import { Button } from 'react-bootstrap'

const UsersList = () => {
  const validationSchema = yup.object().shape({
    title: yup.string().required('სათაურის დაწერა აუცილებელია'),
    imageUrl: yup.string().required('სურათი აუცილებელია'),
    indexUrl: yup.string(),
  })

  const initialValues = {
    title: '',
    imageUrl: '',
    indexUrl: '',
  }

  // data for the table
  const [tableData, setTableData] = useState([])
  const [loading, setloading] = useState(false)
  const [modalShow, setModalShow] = useState(false)

  // Index Data
  useEffect(() => {
    const getIndexList = async () => {
      await axios.get('/index').then(response => {
        setloading(true)
        setTableData(response.data)
      })
    }
    getIndexList()
    setloading(false)

    return () => {
      setTableData([])
    }
  }, [])

  // Delete Index
  const deleteIndex = id => {
    axios.delete(`/index/${id}`, {
      headers: { accessToken: localStorage.getItem('accessToken') },
    })
    window.location.reload(false)
  }

  // Table columns
  const columns = [
    { field: 'id', title: 'ID', editable: 'never' },
    {
      field: 'imageUrl',
      title: 'სურათი',
      render: item => (
        <img
          src={item.imageUrl}
          alt='pic'
          border='3'
          height='100'
          width='100'
          style={{ objectFit: 'contain' }}
        />
      ),
    },
    { field: 'title', title: 'დასახელება' },
    { field: 'indexUrl', title: 'ინდექსის ლინკი' },
    { field: `createdAt`, title: 'დამატების ტარიღი', type: 'date', editable: 'never' },
  ]

  function MyVerticallyCenteredModal(props) {
    // Add Index
    const handleSubmit = data => {
      axios.post('/index', data).then(response => {
        window.location.reload(true)
      })
    }
    return (
      <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>ახალი ინდექსის დამატება</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <label>დასახელება ქართულად</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <ErrorMessage style={{ color: 'red' }} name='title' component='span' />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <Field
                    style={{ width: '100%' }}
                    autoComplete='off'
                    id='inputCreatePost'
                    name='title'
                    placeholder='დასახელება'
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <label>სურათის ლინკი</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <ErrorMessage style={{ color: 'red' }} name='imageUrl' component='span' />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <Field
                    style={{ width: '100%', marginBottom: '20px' }}
                    autoComplete='off'
                    id='inputCreatePost'
                    name='imageUrl'
                    placeholder='სურათის ლინკი'
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <label>ინდექსის ლინკი</label>
                </Grid>
                {/* <Grid item xs={12} style={{ textAlign: "center", width: "100%" }}>
                  <ErrorMessage style={{ color: "red" }} name='indexUrl' component='span' />
                </Grid> */}
                <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
                  <Field
                    style={{ width: '100%', marginBottom: '20px' }}
                    autoComplete='off'
                    id='inputCreatePost'
                    name='indexUrl'
                    placeholder='ინდექსის ლინკი'
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Button variant='primary' type='submit'>
                    დამატება
                  </Button>
                  <Button onClick={() => setModalShow(false)}>Close</Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  }

  return (
    <>
      <Container>
        <div className='admin-user-list'>
          <div className='createInfoCard-container'>
            <div className='admin-information'>
              <div className='add-new-index'>
                <Button variant='primary' onClick={() => setModalShow(true)}>
                  ახალი ინდექსი
                </Button>
                <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
              </div>
              <div style={{ height: 760, width: '100%' }}>
                <MaterialTable
                  title='ინდექსი'
                  columns={columns}
                  data={tableData}
                  isLoading={!loading}
                  editable={{
                    onRowDelete: selectedRow =>
                      new Promise(resolve => {
                        const index = selectedRow.tableData.id
                        const updatedRows = [...tableData]
                        updatedRows.splice(index, 1)
                        deleteIndex(selectedRow.id)
                        setTimeout(() => {
                          setTableData(updatedRows)
                          resolve()
                        }, 500)
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise(resolve => {
                        const updatedData = [...tableData]
                        updatedData[oldData.tableData.id] = newData
                        // Update Indexes
                        const updateRow = id => {
                          axios.put(
                            '/index/indexupdate',
                            {
                              id: id,
                              enteredTitle: newData.title,
                              enteredImageUrl: newData.imageUrl,
                              enteredIndexUrl: newData.indexUrl,
                              selectedIndex: newData.index,
                            },
                            {
                              headers: { accessToken: localStorage.getItem('accessToken') },
                            }
                          )
                        }
                        updateRow(oldData.id)
                        setTimeout(() => {
                          setTableData(updatedData)
                          resolve()
                        }, 500)
                      }),
                  }}
                  options={{
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    exportButton: true,
                    columnsButton: true,
                    pageSizeOptions: [5, 10],
                    paginationType: 'stepped',
                    sorting: true,
                    filtering: true,
                    paging: true,
                    showFirstLastPageButtons: true,
                    paginationPosition: 'bottom',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default UsersList
