import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Formik } from 'formik'
import * as yup from 'yup'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { countries, countriesGe } from './Data'

import './Register.css'

import { Row, Col, Form, Button, InputGroup, Container } from 'react-bootstrap'

const Register = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const userHelper = () => {
    return (
      <>
        <ul>
          <li>{t('Registration.error1')}</li>
          <li>{t('Registration.error2')}</li>
          {t('Registration.error3') !== '' ? <li>{t('Registration.error3')}</li> : null}
        </ul>
      </>
    )
  }
  const USERNAME_REGEX = /^[a-z0-9_]{3,}[a-z]+[0-9]*$/
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const schema = yup.object().shape({
    username: yup.string().matches(USERNAME_REGEX, userHelper).required(userHelper),
    userfullname: yup.string().required('სავალდებული ველი'),
    email: yup
      .string()
      .email()
      .min(4)
      .matches(EMAIL_REGEX, `${'Registration.erroremail'}`)
      .required(),
    password: yup
      .string()
      .min(6)
      .required(`${t('Registration.requiredfield')}`),
    country: yup.string().required(`${t('Registration.requiredfield')}`),
    address: yup.string().required(`${t('Registration.requiredfield')}`),
    phonenumber: yup.string().required(`${t('Registration.requiredfield')}`),
    birthdate: yup.string().required(`${t('Registration.requiredfield')}`),
    passwordConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], `${t('Registration.errorpassword')}`),
  })

  let pswdType = 'password'
  if (!show) {
    pswdType = 'password'
  } else {
    pswdType = 'text'
  }

  let history = useHistory()

  return (
    <Container>
      <Formik
        // validateOnBlur={true}
        initialValues={{
          username: '',
          userfullname: '',
          birthdate: '',
          country: `${t('Registration.initialCountry')}`,
          address: '',
          phonenumber: '',
          email: '',
          password: '',
          passwordConfirmation: '',
          role: 'User',
        }}
        validationSchema={schema}
        onSubmit={async values => {
          const response = await axios.post('/users', values)
          if (response.data.error) {
            alert(response.data.error)
          } else {
            history.push('/login')
          }
        }}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <div className='register-page'>
            <Row className='justify-content-md-center register-row'>
              <h4>{t('Registration.register')}</h4>
              <Col md='5' className='register-form'>
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group controlId='validationFormikUsername'>
                    <Form.Label>{t('Registration.user')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type='text'
                        placeholder={t('Registration.username')}
                        aria-describedby='inputGroupPrepend'
                        name='username'
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={!!errors.username}
                        isValid={touched.username && !errors.username}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.username}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='validationFormikFullname'>
                    <Form.Label>{t('Registration.fullname')}</Form.Label>
                    <Form.Control
                      type='text'
                      name='userfullname'
                      placeholder={t('Registration.fullname')}
                      value={values.userfullname}
                      onChange={handleChange}
                      isInvalid={!!errors.userfullname}
                      isValid={touched.userfullname && !errors.userfullname}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='validationFormikBirthdate'>
                    <Form.Label>{t('Registration.birthdate')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type='date'
                        placeholder={t('Registration.birthdate')}
                        aria-describedby='inputGroupPrepend'
                        name='birthdate'
                        value={values.birthdate}
                        onChange={handleChange}
                        isInvalid={!!errors.birthdate}
                        isValid={touched.birthdate && !errors.birthdate}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.birthdate}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='validationFormikCountry2'>
                    <Form.Label>{t('Registration.country')}</Form.Label>
                    <Form.Control
                      as='select'
                      name='country'
                      value={values.country}
                      onChange={handleChange}>
                      {t('Registration.language') !== 'ka' ? (
                        <>
                          {countries.map(country => {
                            return <option key={country.code}>{country.name}</option>
                          })}
                        </>
                      ) : (
                        <>
                          {countriesGe.map(country => {
                            return <option key={country.code}>{country.name}</option>
                          })}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId='validationFormikAddress'>
                    <Form.Label>{t('Registration.address')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type='text'
                        placeholder={t('Registration.address')}
                        aria-describedby='inputGroupPrepend'
                        name='address'
                        value={values.address}
                        onChange={handleChange}
                        isInvalid={!!errors.address}
                        isValid={touched.address && !errors.address}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.address}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='validationFormikPhonenumber'>
                    <Form.Label>{t('Registration.phonenumber')}</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type='text'
                        placeholder={t('Registration.phonenumber')}
                        aria-describedby='inputGroupPrepend'
                        name='phonenumber'
                        value={values.phonenumber}
                        onChange={handleChange}
                        isInvalid={!!errors.phonenumber}
                        isValid={touched.phonenumber && !errors.phonenumber}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.phonenumber}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='validationFormikEmail'>
                    <Form.Label>{t('Registration.email')}</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      placeholder={t('Registration.email')}
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      isValid={touched.email && !errors.email}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='validationFormikPassword'>
                    <Form.Label>{t('Registration.password')}</Form.Label>
                    <div className='password'>
                      <Form.Control
                        type={pswdType}
                        placeholder={t('Registration.password')}
                        name='password'
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                        isValid={touched.password && !errors.password}
                      />
                      <InputGroup.Text onClick={() => setShow(!show)} id='inputGroupPrepend'>
                        {show ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      </InputGroup.Text>
                    </div>
                    <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='validationFormikRepassword'>
                    <Form.Label>{t('Registration.repassword')}</Form.Label>
                    <div className='password'>
                      <Form.Control
                        required
                        type={pswdType}
                        placeholder={t('Registration.repassword')}
                        name='passwordConfirmation'
                        value={values.passwordConfirmation}
                        onChange={handleChange}
                        isInvalid={!!errors.passwordConfirmation}
                        isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                      />
                      <InputGroup.Text onClick={() => setShow(!show)} id='inputGroupPrepend'>
                        {show ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      </InputGroup.Text>
                    </div>
                    <Form.Control.Feedback type='invalid'>
                      {errors.passwordConfirmation}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button onClick={handleSubmit}>{t('Registration.registrationbutton')}</Button>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </Container>
  )
}

export default Register
