import './Guide.css'
import { Row, Col } from 'react-bootstrap'
// import HeroComponent from '../../components/HeroComponent/HeroComponent';
import PageThree from '../../components/HeroComponent/components/PageThree'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'
import { Helmet } from 'react-helmet'

function Guide() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('Guide.title')}</title>
        <link rel='canonical' href='http://moambe.org.ge/guide' />
        <meta
          name='moambe, guide, მოამბე, მეგზური'
          description='moambe, guide, მოამბე, მეგზური'
          charSet='utf-8'
        />
      </Helmet>
      <PageThree />
      <div className='homepage-container'>
        <Container>
          <div className='for-authors'>
            <Row>
              <Col>
                <div className='archive-title' style={{ marginTop: '50px' }}>
                  <div className='border-contact-home' />
                  <h4>{t('Guide.title')}</h4>
                  <div className='border-contact-home' />
                </div>
                <ol>
                  <li>{t('Guide.first')}</li>
                  <br />
                  <li>
                    {t('Guide.second.part1')}
                    <ul>
                      <li>{t('Guide.second.part2')}</li>
                      <li>{t('Guide.second.part3')}</li>
                      <li>{t('Guide.second.part4')}</li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    {t('Guide.third.part1')}
                    <ul>
                      <li>{t('Guide.third.part2')}</li>
                      <li>{t('Guide.third.part3')}</li>
                      <li>{t('Guide.third.part4')}</li>
                      <li>{t('Guide.third.part5')}</li>
                      <li>{t('Guide.third.part6')}</li>
                      <li>{t('Guide.third.part7')}</li>
                      <li>{t('Guide.third.part8')}</li>
                      <li>{t('Guide.third.part9')}</li>
                      <li>{t('Guide.third.part10')}</li>
                      <li>{t('Guide.third.part11')}</li>
                      <li>{t('Guide.third.part12')}</li>
                      {`${t('Guide.third.part13')}` === '' ? null : (
                        <li>{t('Guide.third.part13')}</li>
                      )}
                      {`${t('Guide.third.part14')}` === '' ? null : (
                        <li>{t('Guide.third.part14')}</li>
                      )}
                    </ul>
                  </li>
                  <br />
                  <li>{t('Guide.fourth')}</li>
                  <br />
                  <li>{t('Guide.fifth')}</li>
                  <br />
                  <li>{t('Guide.sixth')}</li>
                  <br />
                  <li>{t('Guide.seventh')}</li>
                </ol>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Guide
