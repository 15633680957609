
export const countries = [
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
]


  export const countriesGe = [
      {name: 'ალბანეთი', კოდი: 'AL'},
      {name: 'ალჟირი', კოდი: 'DZ'},
      {name: 'ამერიკული სამოა', კოდი: 'AS'},
      {name: 'ანგოლა', კოდი: 'AO'},
      {name: 'ანტარქტიდა', კოდი: 'AQ'},
      {name: 'ანტიგუა და ბარბუდა', კოდი: 'AG'},
      {name: 'არგენტინა', კოდი: 'AR'},
      {name: 'სომხეთი', კოდი: 'AM'},
      {name: 'არუბა', კოდი: 'AW'},
      {name: 'ავსტრალია', კოდი: 'AU'},
      {name: 'ავსტრია', კოდი: 'AT'},
      {name: 'აზერბაიჯანი', კოდი: 'AZ'},
      {name: 'ბაჰამის კუნძულები', კოდი: 'BS'},
      {name: 'ბაჰრეინი', კოდი: 'BH'},
      {name: 'ბანგლადეში', კოდი: 'BD'},
      {name: 'ბარბადოსი', კოდი: 'BB'},
      {name: 'ბელარუსი', კოდი: 'BY'},
      {name: 'ბელგია', კოდი: 'BE'},
      {name: 'ბელიზი', კოდი: 'BZ'},
      {name: 'ბენინი', კოდი: 'BJ'},
      {name: 'ბერმუდა', კოდი: 'BM'},
      {name: 'ბუტანი', კოდი: 'BT'},
      {name: 'ბოლივია', კოდი: 'BO'},
      {name: 'ბოსნია და ჰერცეგოვინა', კოდი: 'BA'},
      {name: 'ბოტსვანა', კოდი: 'BW'},
      {name: 'ბუვეს კუნძული', კოდი: 'BV'},
      {name: 'ბრაზილია', კოდი: 'BR'},
      {name: 'ბრიტანეთის ინდოეთის ოკეანის ტერიტორია', კოდი: 'IO'},
      {name: 'ბრუნეი დარუსალამი', კოდი: 'BN'},
      {name: 'ბულგარეთი', კოდი: 'BG'},
      {name: 'ბურკინა ფასო', კოდი: 'BF'},
      {name: 'ბურუნდი', კოდი: 'BI'},
      {name: 'კამბოჯა', კოდი: 'KH'},
      {name: 'კამერუნი', კოდი: 'CM'},
      {name: 'კანადა', კოდი: 'CA'},
      {name: 'კაიმანის კუნძულები', კოდი: 'KY'},
      {name: 'ცენტრალური აფრიკის რესპუბლიკა', კოდი: 'CF'},
      {name: 'ჩადი', კოდი: 'TD'},
      {name: 'ჩილე', კოდი: 'CL'},
      {name: 'ჩინეთი', კოდი: 'CN'},
      {name: 'შობის კუნძული', კოდი: 'CX'},
      {name: 'კოლუმბია', კოდი: 'CO'},
      {name: 'კომორის კუნძულები', კოდი: 'KM'},
      {name: 'კონგო', კოდი: 'CG'},
      {name: 'კონგო, დემოკრატიული რესპუბლიკა', კოდი: 'CD'},
      {name: 'კუკის კუნძულები', კოდი: 'CK'},
      {name: 'კოსტა რიკა', კოდი: 'CR'},
      {name: 'ხორვატია', კოდი: 'HR'},
      {name: 'კუბა', კოდი: 'CU'},
      {name: 'კვიპროსი', კოდი: 'CY'},
      {name: 'ჩეხეთი', კოდი: 'CZ'},
      {name: 'დანია', კოდი: 'DK'},
      {name: 'ჯიბუტი', კოდი: 'DJ'},
      {name: 'დომინიკა', კოდი: 'DM'},
      {name: 'დომინიკის რესპუბლიკა', კოდი: 'DO'},
      {name: 'ეკვადორი', კოდი: 'EC'},
      {name: 'ეგვიპტე', კოდი: 'EG'},
      {name: 'ელ სალვადორი', კოდი: 'SV'},
      {name: 'ეკვატორული გვინეა', კოდი: 'GQ'},
      {name: 'ესტონეთი', კოდი: 'EE'},
      {name: 'ეთიოპია', კოდი: 'ET'},
      {name: 'ფიჯი', კოდი: 'FJ'},
      {name: 'ფინეთი', კოდი: 'FI'},
      {name: 'საფრანგეთი', კოდი: 'FR'},
      {name: 'საქართველო', კოდი: 'GE'},
      {name: 'გერმანია', კოდი: 'DE'},
      {name: 'საბერძნეთი', კოდი: 'GR'},
      {name: 'გრენლანდია', კოდი: 'GL'},
      {name: 'გუამი', კოდი: 'GU'},
      {name: 'გვატემალა', კოდი: 'GT'},
      {name: 'გვინეა', კოდი: 'GN'},
      {name: 'ჰონკონგი', კოდი: 'HK'},
      {name: 'უნგრეთი', კოდი: 'HU'},
      {name: 'ისლანდია', კოდი: 'IS'},
      {name: 'ინდოეთი', კოდი: 'IN'},
      {name: 'ინდონეზია', კოდი: 'ID'},
      {name: 'ერაყი', კოდი: 'IQ'},
      {name: 'ირლანდია', კოდი: 'IE'},
      {name: 'ისრაელი', კოდი: 'IL'},
      {name: 'იტალია', კოდი: 'IT'},
      {name: 'იამაიკა', კოდი: 'JM'},
      {name: 'იაპონია', კოდი: 'JP'},
      {name: 'ჯერსი', კოდი: 'JE'},
      {name: 'ყაზახეთი', კოდი: 'KZ'},
      {name: 'კენია', კოდი: 'KE'},
      {name: 'კირიბატი', კოდი: 'KI'},
      {name: 'კორეა, რესპუბლიკა', კოდი: 'KR'}, 
      {name: 'კუვეიტი', კოდი: 'KW'},
      {name: 'ყირგიზეთი', კოდი: 'KG'},
      {name: 'ლატვია', კოდი: 'LV'},
      {name: 'ლიბანი', კოდი: 'LB'},
      {name: 'ლესოტო', კოდი: 'LS'},
      {name: 'ლიბერია', კოდი: 'LR'},
      {name: 'ლიბიის არაბული ჯამაჰირია', კოდი: 'LY'},
      {name: 'ლიხტენშტეინი', კოდი: 'LI'},
      {name: 'ლიტვა', კოდი: 'LT'},
      {name: 'ლუქსემბურგი', კოდი: 'LU'},
      {name: 'მაკაო', კოდი: 'MO'},
      {name: 'მალაიზია', კოდი: 'MY'},
  {name: 'მექსიკა', კოდი: 'MX'},
  {name: 'მოლდოვა, რესპუბლიკა', კოდი: 'MD'},
  {name: 'მონაკო', კოდი: 'MC'},
  {name: 'მონღოლეთი', კოდი: 'MN'},
  {name: 'მონსერატი', კოდი: 'MS'},
  {name: 'მაროკო', კოდი: 'MA'},
  {name: 'მოზამბიკი', კოდი: 'MZ'},
  {name: 'მიანმარი', კოდი: 'MM'},
  {name: 'ნამიბია', კოდი: 'NA'},
  {name: 'ნაურუ', კოდი: 'NR'},
  {name: 'ნეპალი', კოდი: 'NP'},
  {name: 'ნიდერლანდები', კოდი: 'NL'},
  {name: 'ნიდერლანდების ანტილები', კოდი: 'AN'},
  {name: 'ახალი კალედონია', კოდი: 'NC'},
  {name: 'ახალი ზელანდია', კოდი: 'NZ'},
  {name: 'ნიკარაგუა', კოდი: 'NI'},
  {name: 'ნიგერი', კოდი: 'NE'},
  {name: 'ნიგერია', კოდი: 'NG'},
  {name: 'ნორვეგია', კოდი: 'NO'},
  {name: 'ომანი', კოდი: 'OM'},
  {name: 'პაკისტანი', კოდი: 'PK'},
  {name: 'პალაუ', კოდი: 'PW'},
  {name: 'პანამა', კოდი: 'PA'},
  {name: 'პარაგვაი', კოდი: 'PY'}, 
  {name: 'პერუ', კოდი: 'PE'},
  {name: 'ფილიპინები', კოდი: 'PH'},
  {name: 'პიტკერნი', კოდი: 'PN'},
  {name: 'პოლონეთი', კოდი: 'PL'},
  {name: 'პორტუგალია', კოდი: 'PT'},
  {name: 'პუერტო რიკო', კოდი: 'PR'},
  {name: 'კატარი', კოდი: 'QA'}, 
  {name: 'რუმინეთი', კოდი: 'RO'},
  {name: 'რუსეთის ფედერაცია', კოდი: 'RU'},
  {name: 'წმინდა ელენა', კოდი: 'SH'},
  {name: 'სამოა', კოდი: 'WS'},
  {name: 'საუდის არაბეთი', კოდი: 'SA'},
  {name: 'სენეგალი', კოდი: 'SN'},
  {name: 'სერბეთი და ჩერნოგორია', კოდი: 'CS'},
  {name: 'სეიშელის კუნძულები', კოდი: 'SC'},
  {name: 'სინგაპური', კოდი: 'SG'},
  {name: 'სლოვაკია', კოდი: 'SK'},
  {name: 'სლოვენია', კოდი: 'SI'},
  {name: 'სოლომონის კუნძულები', კოდი: 'SB'},
  {name: 'სომალეთი', კოდი: 'SO'},
  {name: 'სამხრეთ აფრიკა', კოდი: 'ZA'},
  {name: 'ესპანეთი', კოდი: 'ES'},
  {name: 'შრი-ლანკა', კოდი: 'LK'},
  {name: 'სუდანი', კოდი: 'SD'},
  {name: 'სურინამი', კოდი: 'SR'},
  {name: 'შვედეთი', კოდი: 'SE'},
  {name: 'შვეიცარია', კოდი: 'CH'},
  {name: 'სირიის არაბთა რესპუბლიკა', კოდი: 'SY'},
  {name: 'ტაივანი, ჩინეთის პროვინცია', კოდი: 'TW'},
  {name: 'ტაჯიკეთი', კოდი: 'TJ'},
  {name: 'ტაილანდი', კოდი: 'TH'},
  {name: 'ტუნისი', კოდი: 'TN'},
  {name: 'თურქეთი', კოდი: 'TR'},
  {name: 'თურქმენეთი', კოდი: 'TM'},
  {name: 'უგანდა', კოდი: 'UG'},
  {name: 'უკრაინა', კოდი: 'UA'},
  {name: 'არაბული გაერთიანებული საემიროები', კოდი: 'AE'},
  {name: 'გაერთიანებული სამეფო', კოდი: 'GB'},
  {name: 'შეერთებული შტატები', კოდი: 'US'},
  {name: 'ურუგვაი', კოდი: 'UY'},
  {name: 'უზბეკეთი', კოდი: 'UZ'},
  {name: 'ვენესუელა', კოდი: 'VE'},
  {name: 'ვიეტნამი', კოდი: 'VN'},
  {name: 'იემენი', კოდი: 'YE'},
  {name: 'ზამბია', კოდი: 'ZM'},
  {name: 'ზიმბაბვე', კოდი: 'ZW'}
  ]