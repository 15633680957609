import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../helpers/AuthContext';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineMessage } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import LogoEng from '../../assets/images/webLogo/gnas-logo-eng.png';

const navList = [
  {
    name: 'პროფილი',
    link: '/user/myprofile',
    icons: <CgProfile />,
  },
  {
    name: 'შეტყობინება',
    link: '/user/submitions',
    icons: <AiOutlineMessage />,
  },
];

const userNav = navList.map((e, i) => {
  return (
    <li
      className='list-row'
      key={i}
      id={window.location.pathname === e.link ? 'active' : ''}
      onClick={() => {
        window.location.pathname = e.link;
      }}
    >
      <i id='icon'>{e.icons}</i>
      <span id='title'>{e.name}</span>
    </li>
  );
});

const UserNav = () => {
  const [open, setOpen] = useState(window.innerWidth < 1367 ? true : false);
  const { setAuthState } = useContext(AuthContext);
  const handleSidebar = () => {
    setOpen(!open);
  };

  let history = useHistory();
  const logout = () => {
    localStorage.removeItem('accessToken');
    setAuthState(false);
    history.push('/');
  };

  return (
    <>
      <div className={open ? 'sidebar' : 'sidebar active'}>
        <div className='logo_content'>
          <div className='logo'>
            <div className='logo_name'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <a href='/'>
                  <img style={{ width: '150px', margin: '30px 20px 0 0' }} src={LogoEng} alt='logo' />
                </a>
              </div>
            </div>
          </div>
          <GiHamburgerMenu id='btn' onClick={handleSidebar} />
        </div>
        <ul className='sidebarList'>{userNav}</ul>
        <div className='logout-from-dashboard'>
          <div className='user-logout'>
            <ul className='sidebarList'>
              <li className='list-row' onClick={logout}>
                <i id='icon'>
                  <FiLogOut />
                </i>
                <span id='title'>გასვლა</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserNav;
