import React from "react";
import { Route } from "react-router-dom";
import AdminNav from "../Pages/Admin/AdminNav";
import NoMatch from "../Pages/NoMatch/NoMatch";

const AdminRoute = ({ isAuth, isAdmin, component: Component, ...rest }) => {
  if (isAdmin === "Admin" && isAuth) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <AdminNav setAuthState={isAuth} />
              <div className='dashboard-component'>
                <Component />
              </div>
            </>
          );
        }}
      />
    );
  } else {
    return <NoMatch />;
  }
};

export default AdminRoute;