import IndexList from './IndexList';
import './Indexing.css';
import { Row } from 'react-bootstrap';
import HeroComponent from '../../components/HeroComponent/HeroComponent';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Indexing = () => {
  const { t } = useTranslation()
  return (
    <>
    <Helmet>
        <meta charSet='utf-8' />
        <title>{t('index.index')}</title>
        <link rel='canonical' href='http://moambe.org.ge/contact' />
        <meta
          name='moambe, index, მოამბე, ინდექსი'
          description='moambe, index, მოამბე, ინდექსი'
          charSet='utf-8'
        />
      </Helmet>
      <HeroComponent />
      <div className='homepage-container'>
        <div className='index-container'>
          <div className='index-header'>
            <Row>
              <IndexList />
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Indexing;
