import { useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const EnterEmail = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')

  const submitEmail = e => {
    if (email !== null) {
      e.preventDefault()
      const requestChangeAddress = async () => {
        await axios.post('/users/reset', { email: email })
      }
      requestChangeAddress()
    }
  }
  return (
    <div>
      <Container>
        <Row className='register-row register-page'>
          <Alert variant='primary'>
            <h4>{t('Login.enteremail')}</h4>
          </Alert>
          <Col md='5' className='login-form'>
            <Form className='register-form' onSubmit={submitEmail}>
              <Form.Group id='inputCreatePost'>
                <Form.Control
                  placeholder='example@gmail.com'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type='text'
                  name='address'
                />
              </Form.Group>
              <Button variant='primary' type='submit' className='register-page btn'>
                {t('Login.enteremailbutton')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EnterEmail
