import { useContext } from 'react';
import { NavContext } from '../../helpers/NavContext';
import './MobileNavigation.css';
import NavbarLinks from './NavbarLinks';

import { FiMenu } from 'react-icons/fi';
import { VscClose } from 'react-icons/vsc';
import WebLogo from '../webLogo/WebLogo';

function MobileNavigation(props) {
  const { open, setOpen } = useContext(NavContext);

  const hamburgerIcon = <FiMenu size='30' className='hamburger' onClick={() => setOpen(!open)} />;

  const closeIcon = <VscClose size='32' className='hamburger closing' onClick={() => setOpen(!open)} />;
  return (
    <nav className='mobile-nav'>
      <WebLogo />
      <div className='overley-content'>
        {open ? closeIcon : hamburgerIcon}
        {open && <NavbarLinks role={props.role} />}
      </div>
    </nav>
  );
}

export default MobileNavigation;
