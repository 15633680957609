import { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const UserSubmition = () => {
  const { t } = useTranslation()
  const [tableData, setTableData] = useState([{}])
  const [loading, setloading] = useState(false)
  const username = localStorage.getItem('username')
  const columns = [
    { field: 'field', title: `${t('submitionpage.inputfields.options.selecttitle')}` },
    { field: 'manuscript', title: `${t('submitionpage.inputfields.title')}` },
    { field: 'abstract', title: `${t('submitionpage.inputfields.abstract')}` },
    { field: 'keywords', title: `${t('submitionpage.inputfields.keywords')}` },
    {
      field: 'article',
      title: `${t('submitionpage.attachyourfile')}`,
      render: rowData => (
        <a
          href={process.env.REACT_APP_SERVER_URL + rowData.article}
          target='_blank'
          rel='noopener noreferrer'>
          {t('submitionpage.attachyourfile')}
        </a>
      ),
    },
    {
      field: 'graphics',
      title: `${t('submitionpage.attachyourgraphicalfile')}`,
      render: rowData => (
        <a
          href={process.env.REACT_APP_SERVER_URL + rowData.graphic}
          target='_blank'
          rel='noopener noreferrer'>
          {t('submitionpage.attachyourgraphicalfile')}
        </a>
      ),
    },
    {
      field: 'document',
      title: `${t('submitionpage.attachyourdocuments')}`,
      render: rowData => (
        <a
          href={process.env.REACT_APP_SERVER_URL + rowData.document}
          target='_blank'
          rel='noopener noreferrer'>
          {t('submitionpage.attachyourdocuments')}
        </a>
      ),
    },
    {
      field: 'status',
      title: `${t('submitionpage.status')}`,
      lookup: {
        Sent: 'გაგზავნილი',
        Pending: 'მოლოდინში',
        Published: 'გამოქვეყნებული',
        Reviewing: 'განიხილება',
      },
    },
    { field: `createdAt`, title: 'გაგზავნის თარიღი', type: 'date', editable: 'never' },
  ]

  // Get Data from API
  useEffect(() => {
    const getData = () => {
      axios.get(`/submitions/byid/${username}`).then(response => {
        setloading(true)
        setTableData(response.data)
      })
    }
    getData()
    setloading(false)
    return () => {
      setTableData([])
    }
  }, [username])
  return (
    <>
      <div className='admin-user-list'>
        <div
          className='createInfoCard-container'
          style={{ justifyContent: 'start', alignItems: 'start' }}>
          <div className='admin-information'>
            <div style={{ height: 760, width: '100%' }}>
              <MaterialTable
                title='გზავნილები'
                columns={columns}
                data={tableData}
                isLoading={!loading}
                editable={false}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  exportButton: true,
                  columnsButton: true,
                  pageSizeOptions: [5, 10],
                  paginationType: 'stepped',
                  sorting: true,
                  filtering: true,
                  paging: true,
                  showFirstLastPageButtons: true,
                  paginationPosition: 'bottom',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserSubmition
