import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import './i18n'
import Loader from "./components/Loader/Loader";
import 'bootstrap/dist/css/bootstrap.css';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root"),
);