import './Navbar.css';
import NavbarLinks from './NavbarLinks';
import MobileNavigation from './MobileNavigation';
import TopBar from '../topBar/TopBar';

function Navbar(props) {
  return (
    <div className='navbar-container'>
      <div className='topbar-component'>
        <TopBar />
      </div>
      <nav className='Nav-bar'>
        <NavbarLinks role={props.userRole} userUsername={props.userUsername} />
        <MobileNavigation role={props.userRole} userUsername={props.userUsername} />
      </nav>
    </div>
  );
}

export default Navbar;
