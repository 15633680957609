// head
import kvesitadze from '../../assets/images/AboutUs/head/kvesitadze.png';
import metreveli from '../../assets/images/AboutUs/head/metreveli.png';
import khurodze from '../../assets/images/AboutUs/head/khurodze.png';

// council
import winfried from '../../assets/images/AboutUs/council/winfried.png';
import leonid from '../../assets/images/AboutUs/council/leonid.png';
import mikhail from '../../assets/images/AboutUs/council/mikhail.png';
import revaz from '../../assets/images/AboutUs/council/revaz.png';
import jost from '../../assets/images/AboutUs/council/jost.png';
import alice from '../../assets/images/AboutUs/council/alice.png';
import gunter from '../../assets/images/AboutUs/council/gunter.png';
import hamlet from '../../assets/images/AboutUs/council/hamlet.png';
// import nicholas from '../../assets/images/AboutUs/council/nicholas.png';
import theo from '../../assets/images/AboutUs/council/theo.png';
import yubin from '../../assets/images/AboutUs/council/yubin.png';
import herbert from '../../assets/images/AboutUs/council/herbert.png';
// import mortimer from '../../assets/images/AboutUs/council/mortimer.png';
import david from '../../assets/images/AboutUs/council/david.png';
import peterh from '../../assets/images/AboutUs/council/peterh.png';
import colin from '../../assets/images/AboutUs/council/colin.png';
import tsivadze from '../../assets/images/AboutUs/council/tsivadze.png';
import zhang from '../../assets/images/AboutUs/council/zhang.jpg';
import zurab_tsereteli from '../../assets/images/AboutUs/council/zurab_tsereteli.jpg';
import sagdeev from '../../assets/images/AboutUs/council/sagdeev.jpg';
import villems from '../../assets/images/AboutUs/council/villems.jpg';

// editorial board
// import aleqsidze from '../../assets/images/AboutUs/editorial/aleqsidze.png'
// import beridze from '../../assets/images/AboutUs/editorial/beridze.png'
// import gamkrelidze from '../../assets/images/AboutUs/editorial/gamkrelidze.png'
import gurgenidze from '../../assets/images/AboutUs/editorial/gurgenidze.png'
import japaridze from '../../assets/images/AboutUs/editorial/japaridze.png'
import khecuriani from '../../assets/images/AboutUs/editorial/khecuriani.png'
// import kokilashvili from '../../assets/images/AboutUs/editorial/kokilashvili.png'
import lortqifanidze from '../../assets/images/AboutUs/editorial/lortqifanidze.png'
import miqeladze from '../../assets/images/AboutUs/editorial/miqeladze.png'
// import muskhelishvili from '../../assets/images/AboutUs/editorial/muskhelishvili.png'
import nadaraia from '../../assets/images/AboutUs/editorial/nadaraia.png'
import nersesiani from '../../assets/images/AboutUs/editorial/nersesiani.png'
import papava from '../../assets/images/AboutUs/editorial/papava.png'
import revishvili from '../../assets/images/AboutUs/editorial/revishvili.png'
import sadunishvili from '../../assets/images/AboutUs/editorial/sadunishvili.png'
// import samsonia from '../../assets/images/AboutUs/editorial/samsonia.png'
import shilakadze from '../../assets/images/AboutUs/editorial/shilakadze.png'
import tsitsishvili from '../../assets/images/AboutUs/editorial/cicishvili.png'
import roinmalakmadze from '../../assets/images/AboutUs/editorial/roinmalakmadze.jpeg'
import giorgimuskhelishvili from '../../assets/images/AboutUs/editorial/giorgimuskhelishvili.png'
import djibuti from '../../assets/images/AboutUs/editorial/djibuti.jpg'
import chelidze from '../../assets/images/AboutUs/editorial/chelidze.jpg'
import elizbar from '../../assets/images/AboutUs/editorial/elizbar.jpg'
import kadeishvili from '../../assets/images/AboutUs/editorial/kadeishvili.png'
import kakhidze from '../../assets/images/AboutUs/editorial/kakhidze.jpg'
import katsarava from '../../assets/images/AboutUs/editorial/katsarava.jpg'
import korakhashvili from '../../assets/images/AboutUs/editorial/korakhashvili.jpg'
import medzmariashvili from '../../assets/images/AboutUs/editorial/medzmariashvili.jpg'
import melikishvili from '../../assets/images/AboutUs/editorial/melikishvili.jpg'
import samushia from '../../assets/images/AboutUs/editorial/samushia.jpg'
import sosiashvili from '../../assets/images/AboutUs/editorial/sosiashvili.jpg'
import sujashvili from '../../assets/images/AboutUs/editorial/sujashvili.jpg'
import ugrekhelidze from '../../assets/images/AboutUs/editorial/ugrekhelidze.jpg'

// collegues

import ludmilagverdwiteli from '../../assets/images/AboutUs/collegue/ludmilagverdwiteli.png'
import mavrabancuri from '../../assets/images/AboutUs/collegue/mavrabancuri.jpg'
import tsisanalejava from '../../assets/images/AboutUs/collegue/tsisanalejava.jpg'
// import teadolidze from '../../assets/images/AboutUs/collegue/teadolidze.jpeg'
import ninojavelidze from '../../assets/images/AboutUs/collegue/ninojavelidze.jpeg'
import tutberidze from '../../assets/images/AboutUs/editorial/tutberidze.jpeg'

export const heads = [
    {title: 'მთავარი რედაქტორი', name: 'როინ მეტრეველი', img: metreveli},
    {title: 'რედაქტორი', name: 'რამაზ ხუროძე', img: khurodze},
    {title: 'რედაქტორი', name: 'ვლადიმერ პაპავა', img: papava},
]

export const council = [
    {title: 'ნიადაგმცოდნეობა', name: 'ვინფრედ ბლუმი', city: 'ვენა, ავსტრია', img: winfried},
    {title: 'კარდიოვასკულარული ქირურგია', name: 'ლეონიდ ბოკერია',  city: 'მოსკოვი, რუსეთი', img: leonid},
    {title: 'ონკოლოგია', name: 'მიხეილ დავიდოვი',  city: 'მოსკოვი, რუსეთი', img: mikhail},
    {title: 'მათემატიკა', name: 'რევაზ გამყრელიძე',  city: 'მოსკოვი, რუსეთი', img: revaz},
    {title: 'შედარებითი ლინგვისტიკა', name: 'იოსტ გიპერტი',  city: 'ფრანკფურტი, გერმანია', img: jost},
    {title: 'ლინგვისტიკა', name: 'ელის ჰარისი',  city: 'ნიუ-იორკი, აშშ', img: alice},
    {title: 'კომპიუტერული და საინფორმაციო მეცნიერებები', name: 'გიუნტერ ჰოთსი',  city: 'გერმანია', img: gunter},
    {title: 'მათემატიკა', name: 'ჰამლეტ ისახანლი', city: 'ბაქო, აზერბაიჯანი', img: hamlet},
    // {title: 'ექსპერიმენტული მედიცინა', name: 'ნიკოლოზ ყიფშიძე',  city: 'ნიუ-იორკი, აშშ', img: nicholas},
    {title: 'ფილოსოფია', name: 'ტეო კობუში',  city: 'გერმანია', img: theo},
    {title: 'მანქანათმშენებლობა და სასოფლო-სამეურნეო ინჟინერია', name: 'იუბინ ლანი',  city: 'გუანჯოუ, ჩინეთი', img: yubin},
    {title: 'საინჟინრო და ტექნიკური მეცნიერებები', name: 'ჰერბერტ მანგი', city: 'ვენა, ავსტრია', img: herbert},
    // {title: 'კოგნიტური ნეირომეცნიერება', name: 'მორტიმერ მიშკინი', city: 'ბეთესდა, მერილენდი, აშშ', img: mortimer},
    {title: 'მიკრობიოლოგია, ვირუსოლოგია', name: 'დავით ფრანგიშვილი', city: 'პარიზი, საფრანგეთი', img: david},
    {title: 'მემცენარეობა', name: 'პიტერ რავენი', city: 'მისური, აშშ', img: peterh},
    {title: 'არქეოლოგიური მეცნიერება', name: 'კოლინ რენფიუ', city: 'კემბრიჯი, დიდი ბრიტანეთი', img: colin},
    {title: 'არქეოლოგიური მეცნიერება', name: 'Roald Z. Sagdeev', city:'კემბრიჯი, დიდი ბრიტანეთი', img: sagdeev},
    {title: 'ხელოვნება', name: 'ზურაბ წერეთელი', city:'მოსკოვი, რუსეთი', img: zurab_tsereteli},
    {title: 'ქიმიური მეცნიერება', name: 'ასლან ცივაძე', city:'მოსკოვი, რუსეთი', img: tsivadze},
    {title: 'მოლეკულური ბიოლოგია, გენეტიკა', name: 'რიჩარდ ვილემსი', city:'ესტონეთი, ტალინი', img: villems},
    {title: 'მანქანათმშენებლობა', name: 'ტიეჟუ ჟანგი', city:'შანდონგი, ჩინეთი', img: zhang}
]

export const editorial = [
    // {title: 'ქართული, სომხური, ალბანური ისტორია და ფილოლოგია', name: 'ზაზა ალექსიძე', img: aleqsidze},
    // {title: 'ბიოლოგია', name: 'თენგიზ ბერიძე', img: beridze},
    // {title: 'გეოლოგია, გეოფიზიკა, ჰიდროლოგია', name: 'ერეკლე გამყრელიძე', img: gamkrelidze},
    {title: 'ჰიდროლოგია', name: 'დავით გურგენიძე', img: gurgenidze},
    {title: 'არქეოლოგია', name: 'ამირან კახიძე', img: kakhidze},
    {title: 'ბიოქიმია', name: 'გიორგი კვესიტაძე', img: kvesitadze},
    {title: 'ბიოტექნოლოგია', name: 'ავთანდილ კორახაშვილი', img: korakhashvili},
    {title: 'დემოლოგია', name: 'ვაჟა ლორთქიფანიძე', img: lortqifanidze},
    {title: 'ისტორია', name: 'როინ მალაყმაძე', img: roinmalakmadze},
    {title: 'ბიოქიმია', name: 'დავით მიქელაძე ', img: miqeladze},
    {title: 'ეთნოლოგია', name: 'ლია მელიქიშვილი ', img: melikishvili},
    {title: 'ინფორმაციული ტექნოლოგიები', name: 'ელგუჯა მეძმარიაშვილი ', img: medzmariashvili},
    {title: 'მოლეკულური გენეტიკა', name: 'გიორგი მუსხელიშვილი', img: giorgimuskhelishvili },
    {title: 'მათემატიკა', name: 'ელიზბარ ნადარაია', img: nadaraia},
    {title: 'ფიზიკა', name: 'ალექსანდრე ნერსესიანი', img: nersesiani},
    {title: 'ბიოტექნოლოგია', name: 'თემურ რევიშვილი', img: revishvili},
    {title: 'ბიოქიმია', name: 'თინათინ სადუნიშვილი', img: sadunishvili},
    {title: 'ისტორია', name: 'ჯაბა სამუშია', img: samushia},
    {title: 'ისტორია', name: 'გიორგი სოსიაშვილი', img: sosiashvili},
    {title: 'სამართალი', name: 'დავით სუჯაშვილი', img: sujashvili},
    {title: 'სამართალი', name: "მინდია უგრეხელიძე", img: ugrekhelidze},
    {title: 'ქიმია', name: "რამაზ ქაცარავა", img: katsarava},
    {title: 'მათემატიკა', name: "თორნიკე ქადეიშვილი", img: kadeishvili},
    {title: 'საინჟინრო და ტექნიკური მეცნიერებანი', name: 'თამაზ შილაკაძე', img: shilakadze},
    {title: 'ქიმია და ქიმიური ტექნოლოგიები', name: "ვლადიმერ ციციშვილი", img: tsitsishvili},
    {title: 'გეოფიზიკა', name: "თამაზ ჭელიძე", img: chelidze},
    {title: 'მედიცინა', name: 'რამაზ ხეცურიანი', img: khecuriani},
    {title: 'აღმოსავლეთმცოდნეობა', name: 'ელიზბარ ჯაველიძე', img: elizbar},
    {title: 'ფიზიკა', name: 'გიორგი ჯაფარიძე', img: japaridze},
    {title: 'ეკონომიკა', name: 'მიხეილ ჯიბუტი', img: djibuti},
    // {title: 'მათემატიკა', name: 'ვახტანგ კოკილაშვილი', img: kokilashvili},
    // {title: 'ისტორია, არქეოლოგია, ეთნოლოგია', name: 'დავით მუსხელიშვილი', img: muskhelishvili},
    // {title: 'გამოყენებითი ეკონომიკა', name: 'ვლადიმერ პაპავა', img: papava},

    // {title: 'ქიმია', name: 'შოთა სამსონია', img: samsonia},
]

export const colleague = [
    {title: 'რედაქციის გამგე', name: 'ლუდმილა გვერდწითელი', img: ludmilagverdwiteli},
    {title: 'რედაქციის გამგის მოადგილე', name: 'ნინო ჯაველიძე', img: ninojavelidze},
    {title: 'სამეცნიერო რედაქტორი', name: 'ცისანა ლეჟავა', img: tsisanalejava},
    {title: 'სამეცნიერო რედაქტორი', name: 'თამარ თუთბერიძე', img: tutberidze},
    {title: 'ტექნიკური რედაქტორი', name: 'მავრა ბანცური', img: mavrabancuri},
    // {title: '', name: '', img: ''},
]

// ENG

export const heads_en = [
    {title: 'Editor-in-Chief', name: 'Roin Metreveli', img: metreveli},
    {title: 'Associate Editor', name: 'Ramaz Khurodze', img: khurodze},
    {title: 'Associate Editor', name: 'Vladimer Papava', img: papava},
]

export const council_en = [
    {title: 'Soil Science', name: 'Winfried E.H. Blum', city: 'Vienna, Austria', img: winfried},
    {title: 'Cardiovascular Surgery', name: 'Leonid A. Bokeria', city: 'Moscow, Russia', img: leonid},
    {title: 'Oncology', name: 'Mikhail Davydov', city:'Moscow, Russia', img: mikhail},
    {title: 'Mathematics', name: 'Revaz Gamkrelidze', city:'Moscow, Russia', img: revaz},
    {title: 'Comparative Linguistics', name: 'Jost Gippert', city:'Frankfurt, Germany', img: jost},
    {title: 'Linguistics', name: 'Alice C. Harris', city:'NY, USA', img: alice},
    {title: 'Computer & Information Sciences', name: 'Günter Hotz', city:'Saarbrücken, Germany', img: gunter},
    {title: 'Mathematics', name: 'Hamlet Isaxanli', city:'Baku, Azerbaijan', img: hamlet},
    // {title: 'Experimental Medicine', name: 'Nicholas Kipshidze', city:'NY, USA', img: nicholas},
    {title: 'Philosophy', name: 'Theo Kobusch', city:'Bonn, Germany', img: theo},
    {title: 'Mechanical and Agricultural Engineering', name: 'Yubin Lan', city:'Guangzhou, China', img: yubin},
    {title: 'Engineering & Technical Sciences', name: 'Herbert Mang', city:'Vienna, Austria', img: herbert},
    // {title: 'Cognitive Neuroscience', name: 'Mortimer Mishkin', city:'Bethesda, MD., USA', img: mortimer},
    {title: 'Microbiology, Virology', name: 'David Prangishvili', city:'Paris, France', img: david},
    {title: 'Plant Biology', name: 'Peter H. Raven', city:'St. Louis, Mo., USA', img: peterh},
    {title: 'Archaeological Science', name: 'Colin Renfrew', city:'Cambridge, UK', img: colin},
    {title: 'Archaeological Science', name: 'Roald Z. Sagdeev', city:'Cambridge, UK', img: sagdeev},
    {title: 'Arts', name: 'Zurab Tsereteli', city:'Moscow, Russia', img: zurab_tsereteli},
    {title: 'Chemical Sciences', name: 'Aslan Tsivadze', city:'Moscow, Russia', img: tsivadze},
    {title: 'Molecular Biology, Genetics', name: 'Richard Villems', city:'Tallinn, Estonia', img: villems},
    {title: 'Vehicle Engineering', name: 'Tiezhu Zhang', city:'China, Shandong', img: zhang}
]

export const editorial_en = [
    // {title: 'Georgian, Armenian, Albanian, History & Philology', name: 'Zaza Aleksidze', img: aleqsidze},
    // {title: 'Biology', name: 'Tengiz Beridze', img: beridze},
    // {title: 'Geology, Geophysics, Hydrology', name: 'Irakli Gamkrelidze', img: gamkrelidze},
    {title: 'Economics', name: 'Tamaz Chelidze', img: chelidze},
    {title: 'Economics', name: 'Mikheil Djibuti', img: djibuti},
    {title: 'Hydrology', name: 'Davit Gurgenidze', img: gurgenidze},
    {title: 'Physics', name: 'George Japaridze', img: japaridze},
    {title: 'Oriental Studies', name: 'Elizbar Javelidze', img: elizbar},
    {title: 'Mathematics', name: 'Tornike Kadeishvili', img: kadeishvili},
    {title: 'Archaeology', name: 'Amiran Kakhidze', img: kakhidze},
    {title: 'Chemistry', name: 'Ramaz Katsarava', img: katsarava},
    {title: 'Medicine', name: 'Ramaz Khetsuriani', img: khecuriani},
    {title: 'Biotechnology', name: 'Avtandil Korakhashvili', img: korakhashvili},
    {title: 'Biochemistry', name: 'Giorgi Kvesitadze', img: kvesitadze},
    // {title: 'Mathematics', name: 'Vakhtang Kokilashvili', img: kokilashvili},
    {title: 'Demology', name: 'Vazha Lortkipanidze', img: lortqifanidze},
    {title: 'History', name: 'Roin Malakmadze', img: roinmalakmadze },
    {title: 'Informative Technologies', name: 'Elguja Medzmariashvili', img: medzmariashvili},
    {title: 'Ethnology', name: 'Lia Melikishvili', img: melikishvili},
    {title: 'Biochemistry', name: 'David Mikeladze', img: miqeladze},
    {title: 'Molecular Genetics', name: 'Georgi Muskhelishvili', img: giorgimuskhelishvili },
    // {title: 'History, Archeology, Ethnology', name: 'David Muskhelishvili', img: muskhelishvili},
    {title: 'Mathematics', name: 'Elizbar Nadaraya', img: nadaraia},
    {title: 'Physics', name: 'Alexander Nersesyan', img: nersesiani},
    // {title: 'Applied Economics', name: 'Vladimer Papava', img: papava},
    {title: 'Biotechnology', name: 'Temur Revishvili', img: revishvili},
    {title: 'Biochemistry', name: 'Tinatin Sadunishvili', img: sadunishvili},
    // {title: 'Chemistry', name: 'Shota Samsoniya', img: samsonia},
    {title: 'History', name: 'Jaba Samushia', img: samushia},
    {title: 'Engineering & Technical Sciences', name: 'Tamaz Shilakadze', img: shilakadze},
    {title: 'History', name: 'Giorgi Sosiashvili', img: sosiashvili},
    {title: 'Law', name: 'David Sujashvili', img: sujashvili},
    {title: 'Chemistry & Chemical Technologies', name: "Vladimer Tsitsishvili ", img: tsitsishvili},
    {title: 'Law', name: "Mindia Ugrekhelidze ", img: ugrekhelidze},

]

export const colleague_en = [
    {title: 'Publisher', name: 'Ludmila Gverdtsiteli', img: ludmilagverdwiteli},
    {title: 'Scientific Editor', name: 'Nino Javelidze', img: ninojavelidze},
    {title: 'Scientific Editor', name: 'Tsisana Lezhava', img: tsisanalejava},
    // {title: 'Scientific Editor', name: 'Tea Dolidze', img: teadolidze},
    {title: 'Scientific Editor', name: 'Tamar Tutberidze', img: tutberidze},

    {title: 'Technical Editor', name: 'Mavra Bantsuri', img: mavrabancuri},
    // {title: '', name: '', img: ''},
]