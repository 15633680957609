import { useContext, useState } from 'react'
import { BsArchive } from 'react-icons/bs'
import { BiAddToQueue } from 'react-icons/bi'
import { HiOutlineUsers } from 'react-icons/hi'
import { AiOutlineMessage, AiOutlineHome } from 'react-icons/ai'
import { FiLogOut, FiSettings } from 'react-icons/fi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { GoGist } from 'react-icons/go'
import { useHistory } from 'react-router'
import { AuthContext } from '../../helpers/AuthContext'
import { IconButton, Tooltip } from '@mui/material'
import LogoEng from '../../assets/images/webLogo/gnas-logo-eng.png'

const navList = [
  {
    name: 'მთავარი',
    link: '/admin/dashboard',
    icons: <AiOutlineHome />,
  },
  {
    name: 'პოსტები',
    link: '/admin/posts',
    icons: <BiAddToQueue />,
  },
  {
    name: 'მომხმარებლები',
    link: '/admin/users',
    icons: <HiOutlineUsers />,
  },
  {
    name: 'ავტორთა გზავნილები',
    link: '/admin/submitions',
    icons: <AiOutlineMessage />,
  },
  {
    name: 'არქივი',
    link: '/admin/archive',
    icons: <BsArchive />,
  },
  {
    name: 'ინდექსი',
    link: '/admin/index',
    icons: <GoGist />,
  },
]

const adminNav = navList.map((e, i) => {
  return (
    <Tooltip key={i} title={e.name} placement='right'>
      <li
        className='list-row'
        key={i}
        id={window.location.pathname === e.link ? 'active' : ''}
        onClick={() => {
          window.location.pathname = e.link
        }}>
        <IconButton>
          <i id='icon'>{e.icons}</i>
        </IconButton>
        <span id='title'>{e.name}</span>
      </li>
    </Tooltip>
  )
})

const AdminNav = () => {
  const [open, setOpen] = useState(window.innerWidth < 1367 ? true : false)
  const { setAuthState } = useContext(AuthContext)
  const handleSidebar = () => {
    setOpen(!open)
  }

  let history = useHistory()
  const logout = () => {
    localStorage.removeItem('accessToken')
    setAuthState(false)
    history.push('/')
  }

  return (
    <>
      <div className={open ? 'sidebar' : 'sidebar active'}>
        <div className='logo_content'>
          <div className='logo'>
            <div className='logo_name'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <a href='/'>
                  <img
                    style={{ width: '150px', margin: '30px 20px 0 0' }}
                    src={LogoEng}
                    alt='logo'
                  />
                </a>
              </div>
            </div>
          </div>
          <GiHamburgerMenu id='btn' onClick={handleSidebar} />
        </div>
        <ul className='sidebarList'>{adminNav}</ul>
        <div className='logout-from-dashboard'>
          <div className='user-logout'>
            <ul className='sidebarList'>
              <Tooltip title='პროფილის მართვა' placement='right'>
                <li
                  className='list-row'
                  id={window.location.pathname.includes('/admin/myprofile') ? 'active' : ''}
                  onClick={() => {
                    window.location.pathname = '/admin/myprofile'
                  }}>
                  <i id='icon'>
                    <FiSettings />
                  </i>
                  <span id='title'>პროფილის მართვა</span>
                </li>
              </Tooltip>
              <Tooltip title='პროფილის დატოვება' placement='right'>
                <li className='list-row' onClick={logout}>
                  <i id='icon'>
                    <FiLogOut />
                  </i>
                  <span id='title'>გასვლა</span>
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminNav
