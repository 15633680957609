import { useState } from 'react'
import axios from 'axios'
import HeroComponent from '../../components/HeroComponent/HeroComponent'
import './Submitions.css'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Submitions = ({ isAuth }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [field, setField] = useState('MATHEMATICS & PHYSICAL SCIENCES')
  const [manuscript, setManuscript] = useState('')
  const [abstract, setAbstract] = useState('')
  const [keywords, setKeywords] = useState('')
  const [status] = useState('Sent')
  const [article, setArticle] = useState()
  const [graphic, setGraphic] = useState()
  const [document, setDocument] = useState()
  const [dataSent, setDataSent] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)

  if (
    localStorage.getItem('accessToken') === null ||
    localStorage.getItem('accessToken') === undefined
  ) {
    history.push('/login')
  }

  function onArticleChange(e) {
    if (e.target.files) return
    if (e.target.files.length < 2 && e.target.files[0].type === 'application/pdf') {
      setArticle(e.target.files[0])
    } else console.log('Wrong amount and/or file type')
  }

  function onGraphicChange(e) {
    if (e.target.files) return
    if (e.target.files.length < 2 && e.target.files[0].type === 'application/pdf') {
      setGraphic(e.target.files[0])
    } else console.log('Wrong amount and/or file type')
  }

  function onDocumentChange(e) {
    if (e.target.files) return
    if (e.target.files.length < 2 && e.target.files[0].type === 'application/pdf') {
      setDocument(e.target.files[0])
    } else console.log('Wrong amount and/or file type')
  }

  const loader = () => {
    return <div className='loadingOnSubmit'></div>
  }

  async function sendData(e) {
    e.preventDefault()
    const formData = new FormData()
    formData.append('username', localStorage.getItem('username'))
    formData.append('field', field)
    formData.append('manuscript', manuscript)
    formData.append('abstract', abstract)
    formData.append('keywords', keywords)
    formData.append('status', status)
    formData.append('article', article, article.name)
    formData.append('graphic', graphic, graphic.name)
    formData.append('document', document, document.name)
    setDataSent(true)
    setLoading(true)
    axios.post('/submitions', formData).then(res => {
      setDataSent(false)
      setLoading(false)
    })
  }

  const optionsData = [
    {
      value: 'MATHEMATICS & PHYSICAL SCIENCES',
      label: `${t('submitionpage.inputfields.options.option1')}`,
    },
    { value: 'Earth Sciences', label: `${t('submitionpage.inputfields.options.option2')}` },
    { value: 'Biological Sciences', label: `${t('submitionpage.inputfields.options.option3')}` },
    { value: 'Social Sciences', label: `${t('submitionpage.inputfields.options.option4')}` },
  ]

  return (
    <>
    <Helmet>
        <meta charSet='utf-8' />
        <title>{t('submitionpage.title')}</title>
        <link rel='canonical' href='http://moambe.org.ge/submit' />
        <meta
          name='moambe, submit, მოამბე, წარდგენა'
          description='moambe, submit, მოამბე, წარდგენა'
          charSet='utf-8'
        />
      </Helmet>
      <HeroComponent />
      <div className='homepage-container'>
        <div className='archive-title' style={{ marginTop: '50px' }}>
          <div className='border-contact-home' />
          <h4>{t('submitionpage.title')}</h4>
          <div className='border-contact-home' />
        </div>
        <div className='submition-page'>
          <form onSubmit={sendData}>
            <table border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td className='contacttext'>
                    <span className='mandatory'>*</span>
                    {t('submitionpage.inputfields.options.selecttitle')}
                  </td>
                  <td>
                    <select
                      name='field'
                      id='field'
                      className='contactstyle'
                      onChange={e => setField(e.target.value)}>
                      {optionsData.map(option => {
                        return (
                          <option
                            key={option.value}
                            value={option.value}
                            aria-selected={t('submitionpage.inputfields.options.option1')}>
                            {option.label}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className='contacttext'>
                    <span className='mandatory'>* {t('submitionpage.inputfields.title')}</span>
                  </td>
                  <td>
                    <input
                      type='text'
                      name='manuscript'
                      id='manuscript'
                      className='contactstyle'
                      onChange={e => setManuscript(e.target.value)}
                      value={manuscript}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='contacttext'>
                    <span className='mandatory'>* {t('submitionpage.inputfields.abstract')}</span>
                  </td>
                  <td>
                    <input
                      type='text'
                      name='abstract'
                      id='abstract'
                      className='contactstyle'
                      onChange={e => setAbstract(e.target.value)}
                      value={abstract}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='contacttext'>
                    <span className='mandatory'>* {t('submitionpage.inputfields.keywords')}</span>
                  </td>
                  <td>
                    <input
                      type='text'
                      name='keywords'
                      id='keywords'
                      className='contactstyle'
                      onChange={e => setKeywords(e.target.value)}
                      value={keywords}
                    />
                  </td>
                </tr>
                <tr>
                  <td width={150} className='contacttext'>
                    <span className='mandatory'>* </span> {t('submitionpage.attachyourfile')}
                  </td>
                  <td>
                    <input
                      type='file'
                      name='foo'
                      onChange={onArticleChange}
                      className='contactstyle choosfile'
                      accept='.pdf'
                    />
                  </td>
                </tr>
                <tr>
                  <td width={150} className='contacttext'>
                    <span className='mandatory'>* </span>
                    {t('submitionpage.attachyourgraphicalfile')}
                  </td>
                  <td>
                    <input
                      type='file'
                      name='foo'
                      onChange={onGraphicChange}
                      className='contactstyle choosfile'
                      accept='.pdf'
                    />
                  </td>
                </tr>
                <tr>
                  <td width={150} className='contacttext'>
                    <span className='mandatory'>*</span> {t('submitionpage.attachyourdocuments')}
                  </td>
                  <td>
                    <input
                      type='file'
                      name='foo'
                      onChange={onDocumentChange}
                      className='contactstyle choosfile'
                      accept='.pdf'
                    />
                  </td>
                </tr>
                <tr>
                  <td />
                  <td>
                    {dataSent ? (
                      loader()
                    ) : (
                      <button
                        type='submit'
                        onSubmit={sendData}
                        name='btnSubmit'
                        id='btnSubmit'
                        className='submitions-upload-btn'>
                        {t('submitionpage.submit')}
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </>
  )
}

export default Submitions
