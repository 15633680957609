import './TopBar.css';
import { AiFillPhone, AiTwotoneMail } from 'react-icons/ai';

const TopBar = () => {
  return (
    <div className='topbar'>
      <ul>
        <li>
          <a href='tel:+995322997593'>
            <AiFillPhone style={{ marginRight: '5px', fontSize: '16px' }} /> (+995 32) 299-75-93 
          </a>
        </li>
        <li>
          <a href='mailto:bulletin@science.org.ge'>
            <AiTwotoneMail style={{ marginRight: '5px', fontSize: '16px' }} /> bulletin@science.org.ge
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TopBar;
