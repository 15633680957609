import { useState, useEffect } from 'react'
import axios from 'axios'
import Archives from './Components/Archives'
import OldArchives from './Components/OldArchives'

const ArchivedList = () => {
  const [archives, setArchives] = useState([])

  useEffect(() => {
    const request = async () => {
      const res = await axios.get('/archive')
      setArchives(res.data)
    }
    request()

    return () => {
      setArchives([])
    }
  }, [])

  return (
    <>
      <div className='archived-files'>
        <Archives archives={archives} />
        <OldArchives archives={archives} />
      </div>
    </>
  )
}

export default ArchivedList
