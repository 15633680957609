import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'

import './Information.css'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Information = () => {
  const { t } = useTranslation()
  const [listOfPosts, setListOfPosts] = useState([])
  let history = useHistory()

  useEffect(() => {
    const getPosts = async () => {
      await axios.get('/posts').then(response => {
        setListOfPosts(response.data)
      })
    }
    getPosts()

    return () => {
      setListOfPosts([])
    }
  }, [])

  return (
    <>
      {listOfPosts.length !== 0 ? (
        <>
          <Row style={{ justifyContent: 'center' }}>
            <div className='border-contact-home' />
            <h5>{t('homepage.informationtitle')}</h5>
            <div className='border-contact-home' />
          </Row>
          <div className='container'>
            <div className='information-components'>
              {listOfPosts.map(value => {
                return (
                  <div key={value.title} className='information-wrapper'>
                    <div
                      onClick={() => {
                        history.push(`/News/${value.id}`)
                      }}
                      style={{ cursor: 'pointer' }}>
                      <img src={value.imageUrl} alt='card-img' />
                      <div className='information-body'>
                        <div className='information-description'>
                          <p>{value.title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Information
