import { useState, useEffect } from 'react'
import axios from 'axios'
import './HomePage.css'
import { Row, Col, Container } from 'react-bootstrap'
import Information from '../../components/Information/Information'
import HeroComponent from '../../components/HeroComponent/HeroComponent'
import { useTranslation } from 'react-i18next'
import ContactForHomePage from '../Contact/ContactForHomePage'
import IndexingForHome from '../Indexing/IndexingForHome/IndexingForHome'
import ArchiveForHomePage from '../Archive/components/ArchiveForHomePage'
import TopImages from './components/TopImages'
import OldCovers from './components/OldCovers'
import { Helmet } from 'react-helmet'

const HomePage = () => {
  const { t } = useTranslation()
  const [data, setData] = useState({})

  useEffect(() => {
    const request = async () => {
      await axios.get('/archive').then(res => {
        const { title, images } = res.data[1]
        setData({ title, images })
      })
    }
    request()

    return () => {
      setData({})
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('homepage.title')}</title>
        <link rel='canonical' href='http://moambe.org.ge/about' />
        <meta name='moambe, მოამბე' description='moambe, მოამბე' charSet='utf-8' />
      </Helmet>
      <HeroComponent />
      <div className='homepage-container'>
        <Container>
          <div id='Home-page' className='home-page'>
            <Row className='homepage-toprow'>
              <Col md='auto' className='moambe-img'>
                <a
                  href='http://moambe.org.ge/static/archive/Archive/2023%20Vol.17/Vol.17%20n.3%202023/index.html'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {data.images !== undefined ? (
                    <img
                      src={`http://moambe.org.ge/${data.images.find(img =>
                        img.includes('kda.jpeg'),
                      )}`}
                      alt='kda'
                    />
                  ) : null}
                  <p>{t('homepage.currentIssue')}</p>
                  <p>{data.title}</p>
                </a>
                <p>ISSN - 0132 - 1447</p>
              </Col>
              <Col
                md='auto'
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                {data.images !== undefined ? <TopImages images={data.images} /> : null}
              </Col>
            </Row>
            <Col>
              <Information />
            </Col>
            <Row>
              <ArchiveForHomePage />
            </Row>
            <Row className='old-cover-images'>
              <OldCovers />
            </Row>
            <Row>
              <ContactForHomePage />
            </Row>
          </div>
          <Col>
            <IndexingForHome />
          </Col>
        </Container>
      </div>
    </>
  )
}

export default HomePage
