import * as React from 'react'
import { withRouter } from 'react-router-dom'
import './AdminPanel.css'
import UserCounter from '../../helpers/UserCounter'
import PostCounter from '../../helpers/PostCounter'

// MUI
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import SubmitionCounter from '../../helpers/SubmitionCounter'

const handleClickUsers = () => {
  window.location.pathname = '/admin/users'
}

const handleClickPosts = () => {
  window.location.pathname = '/admin/posts'
}

const handleClickSubmitions = () => {
  window.location.pathname = '/admin/submitions'
}

const usersCard = (
  <React.Fragment>
    <CardContent style={{ height: '125px' }}>
      <h4 variant='h5' style={{ position: 'relative' }}>
        რეგისტრირებული მომხმარებლები
        <span className='box-content'>
          <UserCounter />
        </span>
      </h4>
    </CardContent>
    <CardActions>
      <Button size='medium' onClick={handleClickUsers}>
        სრულად ნახვა
      </Button>
    </CardActions>
  </React.Fragment>
)

const postsCard = (
  <React.Fragment>
    <CardContent style={{ height: '125px' }}>
      <h4 variant='h5' style={{ position: 'relative' }}>
        პოსტების რაოდენობა
        <span className='box-content'>
          <PostCounter />
        </span>
      </h4>
    </CardContent>
    <CardActions>
      <Button size='medium' onClick={handleClickPosts}>
        მართვა / ახლის დამატება
      </Button>
    </CardActions>
  </React.Fragment>
)

const submitionCard = (
  <React.Fragment>
    <CardContent style={{ height: '125px', width: '20em' }}>
      <h4 variant='h5' style={{ position: 'relative' }}>
        გზავნილების რაოდენობა
        <span className='box-content'>
          <SubmitionCounter />
        </span>
      </h4>
    </CardContent>
    <CardActions>
      <Button size='medium' onClick={handleClickSubmitions}>
        მართვა / ახლის დამატება
      </Button>
    </CardActions>
  </React.Fragment>
)

const AdminPanel = () => {
  return (
    <div className='admin-panel'>
      <div className='admin-dashboard'>
        <ul className='admin-info-box'>
          <li>
            <Box sx={{ width: 350 }}>
              <Card variant='outlined' style={{ backgroundColor: '#ffcb77' }}>
                {usersCard}
              </Card>
            </Box>
          </li>
          <li>
            <Box sx={{ width: 350 }}>
              <Card variant='outlined' style={{ backgroundColor: '#fef9ef' }}>
                {postsCard}
              </Card>
            </Box>
          </li>
          <li>
            <Box sx={{ width: 350 }}>
              <Card variant='outlined' style={{ backgroundColor: '#fef9ef' }}>
                {submitionCard}
              </Card>
            </Box>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default withRouter(AdminPanel)
