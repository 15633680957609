import { Container } from '@mui/material'
import AddNewArchive from './Components/AddNewArchive/AddNewArchive'
import ArchivedList from './Components/ArchivedList/ArchivedList'

const AdminArchive = () => {
  return (
    <>
      <Container>
        <AddNewArchive />
        <ArchivedList />
      </Container>
    </>
  )
}

export default AdminArchive
