import { useEffect } from 'react'
import { useState, useRef } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsSearch } from 'react-icons/bs'
import './SearchModal.css'
import axios from 'axios'

const SearchModal = () => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [inputValue, setInputValue] = useState('')
  const searchValue = useDebounce(inputValue, 1000)
  const [data, setData] = useState([])
  const [showData, setShowData] = useState(false)

  useEffect(() => {
    if (show) {
      inputRef.current.focus()
    }
  }, [show])

  useEffect(() => {
    const requestData = async () => {
      const result = await axios.get('search/q/' + searchValue)
      setData(result.data)
    }

    if (searchValue.length >= 2) {
      requestData()
    } else {
      setData([])
    }

    return () => {
      setData([])
    }
  }, [searchValue])

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(
      () => {
        const handler = setTimeout(() => {
          setDebouncedValue(value)
          if (inputValue.length <= 1) {
            setShowData(false)
          } else {
            setShowData(true)
          }
        }, delay)

        return () => {
          clearTimeout(handler)
          setShowData(false)
        }
      },
      [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
  }

  console.log(data);

  return (
    <>
      <span className='nav-search'>
        <button onClick={handleShow}>
          <BsSearch />
        </button>
      </span>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          // backdrop='static'
          keyboard={true}
          centered>
          <Modal.Body className='search-modal'>
            <form>
              <BsSearch />
              <input
                type='text'
                ref={inputRef}
                onChange={e => setInputValue(e.target.value)}
                placeholder={t('searchbar.placeholder')}
              />
            </form>
          </Modal.Body>
          {showData ? (
            <Modal.Body
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}>
              {data.map(item => {
                return (
                  <div key={item.url} className='search-result'>
                    <a
                      href={`http://moambe.org.ge/${item.url}`}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>
                        <Row>
                          {item.year ? <Col className='search-year'>Year: {item.year}</Col> : null}
                          {item.version ? (
                            <Col md='3' className='search-version'>
                              {item.version}
                            </Col>
                          ) : null}
                        </Row>
                      </span>
                      <span>
                        <Row>
                          {item.title ? (
                            <Col md='12' className='search-year'>
                              Title: {item.title.replace(/_/g, ' ')}
                            </Col>
                          ) : null}
                        </Row>
                      </span>
                    </a>
                  </div>
                )
              })}
            </Modal.Body>
          ) : null}
        </Modal>
      </>
    </>
  )
}

export default SearchModal
