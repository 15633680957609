import { NavDropdown, ListGroup, Col, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

const Archives = ({ archives }) => {
  return (
    <>
      <Col>
        <p>არქივი</p>
        <Row>
          <ListGroup>
            <Col>
              <ListGroup.Item>
                {archives?.length > 0 ? (
                  <>
                    {archives[0].versions.files.map(item => {
                      return (
                        <NavDropdown
                          key={uuidv4()}
                          title={item.folder}
                          id='collasible-nav-dropdown'
                        >
                          {item.attached.map(file => {
                            return (
                              <>
                                <a
                                  key={uuidv4()}
                                  target={'_blank'}
                                  rel='noopener noreferrer'
                                  href={`http://moambe.org.ge${file.html}`}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '10px',
                                  }}
                                >
                                  {file.version}
                                </a>
                              </>
                            )
                          })}
                        </NavDropdown>
                      )
                    })}
                  </>
                ) : (
                  <h1>Loading...</h1>
                )}
              </ListGroup.Item>
            </Col>
          </ListGroup>
        </Row>
      </Col>
    </>
  )
}

export default Archives
