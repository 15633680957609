import { useTranslation } from 'react-i18next'
import HeroComponent from '../../components/HeroComponent/HeroComponent'
import { NavDropdown, ListGroup, Col } from 'react-bootstrap'
import './Archive.css'
import { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Helmet } from 'react-helmet'

const Archive = () => {
  const { t } = useTranslation()
  const url = 'http://moambe.org.ge'
  const [newArchives, setNewArchives] = useState([])
  const [oldArchiveData, setOldArchiveData] = useState([])

  const sortAlgo = useCallback((arr) => {
    return Object.values(arr).sort((a, b) => (a.folder < b.folder ? 1 : -1));
  }, []);


  useEffect(() => {
    const request = async () => {
      const res = await axios.get('/archive')

    const sortedArchives = sortAlgo(res?.data[0]?.versions.files);
    const sortedOldArchives = sortAlgo(res?.data[2]?.versions.files);

      setNewArchives(sortedArchives);
      setOldArchiveData(sortedOldArchives)
    }
    request()

    return () => {
      setNewArchives([])
      setOldArchiveData([])
    }
  }, [sortAlgo])

  return (
    <>
     <Helmet>
        <meta charSet='utf-8' />
        <title>{t('archive.title')}</title>
        <link rel='canonical' href='http://moambe.org.ge/archive' />
        <meta
          name='moambe, archive, მოამბე, არქივი'
          description='moambe, archive, მოამბე, არქივი'
          charSet='utf-8'
        />
      </Helmet>
      <HeroComponent />
      <div className='archive-page'>
        <div className='container'>
          <div className='archive-wrapper'>
            <div className='archive-title'>
              <div className='border-contact-home' />
              <h4>{t('archive.title')}</h4>
              <div className='border-contact-home' />
            </div>
            <div className='archived-files'>
              {newArchives
                ?.slice().sort().map((e, i) => {
                  return (
                    <Col md='3' key={i}>
                      <ListGroup>
                        <ListGroup.Item key={i}>
                          <NavDropdown title={e.folder} id='collasible-nav-dropdown'>
                            {e?.attached?.map((attached, index) => {
                              return (
                                <NavDropdown.Item
                                  href={url + attached.html}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  No.{index + 1}
                                </NavDropdown.Item>
                              )
                            })}
                          </NavDropdown>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  )
                })}
            </div>
            <h5>{t('archive.undertitle')}</h5>
          </div>
          <div
            className='archived-files'
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              color: '#000',
              borderRadius: '5px',
            }}
          >
            {oldArchiveData.map((e, i) => {
              return (
                <Col md='2' key={i}>
                  <ListGroup>
                    <ListGroup.Item>
                      <NavDropdown title={e.folder} id='collasible-nav-dropdown'>
                        {e.attached.map(file => {
                          return (
                            <>
                              <a
                                key={uuidv4()}
                                target={'_blank'}
                                rel='noopener noreferrer'
                                href={`http://moambe.org.ge${file.html}`}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: '10px',
                                }}
                              >
                                {file.version}
                              </a>
                            </>
                          )
                        })}
                      </NavDropdown>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Archive
