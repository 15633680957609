import { useTranslation } from 'react-i18next';
import './PageThree.css';

const PageThree = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='hero-container'>
        <div className='image-container'>
          {/* <img src={gnasMain} alt='gnas' className='hero-image' /> */}
          <span className={`${t('herocomponent.pagethreegeo')}` === 'pageThreeGeo' ? 'pagethreegeo' : 'pagethreeeng'} />
        </div>
      </div>
    </>
  );
};

export default PageThree;
