import { useState, useEffect } from 'react'
import axios from 'axios'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'

const Submition = () => {
  const { t } = useTranslation()
  const [tableData, setTableData] = useState([{}])
  const [loading, setloading] = useState(false)

  // Table columns
  const columns = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'username', title: `${t('Login.username')}`, editable: 'never' },
    {
      field: 'field',
      title: `${t('submitionpage.inputfields.options.selecttitle')}`,
      editable: 'never',
    },
    { field: 'manuscript', title: `${t('submitionpage.inputfields.title')}`, editable: 'never' },
    { field: 'abstract', title: `${t('submitionpage.inputfields.abstract')}`, editable: 'never' },
    {
      field: 'keywords',
      title: `${t('submitionpage.inputfields.keywords')}`,
      editable: 'never',
    },
    {
      field: 'article',
      title: `${t('submitionpage.attachyourfile')}`,
      render: rowData => (
        <a href={rowData.article} target='_blank' rel='noopener noreferrer'>
          {t('submitionpage.attachyourfile')}
        </a>
      ),
      editable: 'never',
    },
    {
      field: 'graphic',
      title: `${t('submitionpage.attachyourgraphicalfile')}`,
      render: rowData => (
        <a href={rowData.graphic} target='_blank' rel='noopener noreferrer'>
          {t('submitionpage.attachyourgraphicalfile')}
        </a>
      ),
      editable: 'never',
    },
    {
      field: 'document',
      title: `${t('submitionpage.attachyourdocuments')}`,
      render: rowData => (
        <a href={rowData.document} target='_blank' rel='noopener noreferrer'>
          {t('submitionpage.attachyourdocuments')}
        </a>
      ),
      editable: 'never',
    },
    {
      field: 'status',
      title: `${t('submitionpage.status')}`,
      lookup: {
        Sent: 'გამოგზავნილი',
        Pending: 'მოლოდინში',
        Published: 'გამოქვეყნებული',
        Reviewing: 'განიხილება',
      },
    },
    {
      field: `createdAt`,
      title: `${t('submitionpage.inputfields.sentdate')}`,
      type: 'date',
      editable: 'never',
    },
  ]

  // Get Data from API
  useEffect(() => {
    const getData = () => {
      axios.get('/submitions').then(response => {
        setloading(true)
        const customArray = response.data.map(item => {
          const articleBlob = new Blob([new Uint8Array(item.article.data)], {
            type: 'application/pdf',
          })
          const articleUrl = URL.createObjectURL(articleBlob)
          item.article = articleUrl

          const graphicBlob = new Blob([new Uint8Array(item.graphic.data)], {
            type: 'application/pdf',
          })
          const graphicUrl = URL.createObjectURL(graphicBlob)
          item.graphic = graphicUrl

          const documentBlob = new Blob([new Uint8Array(item.document.data)], {
            type: 'application/pdf',
          })
          const documentUrl = URL.createObjectURL(documentBlob)
          item.document = documentUrl
          return item
        })
        setTableData(customArray)
      })
    }
    getData()
    setloading(false)

    return () => {
      setTableData([{}])
    }
  }, [])

  // Delete Index
  const deleteSubmition = async id => {
    await axios.delete(`/submitions/${id}`, {
      headers: { accessToken: localStorage.getItem('accessToken') },
    })
    window.location.reload(false)
  }

  return (
    <>
      <div className='admin-user-list'>
        <div
          className='createInfoCard-container'
          style={{ justifyContent: 'start', alignItems: 'start' }}>
          <div className='admin-information'>
            <div style={{ height: 760, width: '100%' }}>
              <MaterialTable
                title='გზავნილები'
                columns={columns}
                data={tableData}
                isLoading={!loading}
                editable={{
                  onRowDelete: selectedRow =>
                    new Promise(resolve => {
                      const index = selectedRow.tableData.id
                      const updatedRows = [...tableData]
                      updatedRows.splice(index, 1)
                      deleteSubmition(selectedRow.id)
                      setTimeout(() => {
                        setTableData(updatedRows)
                        resolve()
                      }, 500)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                      const updatedData = [...tableData]
                      updatedData[oldData.tableData.id] = newData
                      // Update Users
                      const updateRow = async id => {
                        await axios.put(
                          '/submitions/submitionsupdate',
                          {
                            id: id,
                            enteredStatus: newData.status,
                          },
                          {
                            headers: { accessToken: localStorage.getItem('accessToken') },
                          }
                        )
                      }
                      updateRow(oldData.id)
                      setTimeout(() => {
                        setTableData(updatedData)
                        resolve()
                      }, 500)
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  exportButton: true,
                  columnsButton: true,
                  pageSizeOptions: [5, 10],
                  paginationType: 'stepped',
                  sorting: true,
                  filtering: true,
                  paging: true,
                  showFirstLastPageButtons: true,
                  paginationPosition: 'bottom',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Submition
