import { useTranslation } from 'react-i18next';
import './HeroComponent.css';

const HeroComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='hero-container'>
        <div className='image-container'>
          {/* <img src={gnasMain} alt='gnas' className='hero-image' /> */}
          <span className={`${t('herocomponent.classname')}` === 'mainCoverGeo' ? 'mainCoverGeo' : 'mainCoverEng'} />
        </div>
      </div>
    </>
  );
};

export default HeroComponent;
