import { useState, useContext } from 'react'
import { useHistory, Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import '../RegistrationPage/Register.css'
import { useTranslation } from 'react-i18next'

import { Button, Row, Col, Form, Container, Alert } from 'react-bootstrap'
import { AuthContext } from '../../helpers/AuthContext'
import { Helmet } from 'react-helmet'

const Login = () => {
  const { t } = useTranslation()
  const { setAuthState } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  function ErrorMessage({ message }) {
    return (
      <div>
        <>
          <Alert variant='danger'>
            <p>{message}</p>
          </Alert>
        </>
      </div>
    )
  }

  let history = useHistory()

  const login = e => {
    e.preventDefault()
    const data = { username: username, password: password }
    const postUserLogin = () => {
      axios
        .post('/users/login', data)
        .then(response => {
          setAuthState(true)
          localStorage.setItem('accessToken', response.data)
          history.push('/')
        })
        .catch(error => {
          if (error?.response.data.error === "User doesn't exist") {
            setErrorMessage(t('error.userdoesntexist'))
          }
          if (error?.response.data.error === 'Wrong username and password combination') {
            setErrorMessage(t('error.invalidpassword'))
          }
        })
    }
    postUserLogin()
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('Login.authorization')}</title>
        <link rel='canonical' href='http://moambe.org.ge/about' />
        <meta
          name='moambe, archive, მოამბე, არქივი'
          description='moambe, archive, მოამბე, არქივი'
          charSet='utf-8'
        />
      </Helmet>
      <Container>
        <Row className='register-row register-page'>
          <h4>{t('Login.authorization')}</h4>
          <div className='alert'>
            {errorMessage !== '' ? <ErrorMessage message={errorMessage} /> : null}
          </div>
          <Col md='5' className='login-form'>
            <Form className='register-form'>
              <Row>
                <Col>
                  <Form.Group controlId='loginUsername'>
                    <Form.Control
                      type='text'
                      placeholder={t('Login.username')}
                      required
                      onChange={e => setUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId='loginPassword'>
                    <Form.Control
                      type='password'
                      placeholder={t('Login.password')}
                      required
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant='primary'
                    type='submit'
                    onClick={login}
                    className='register-page btn'
                  >
                    {t('Login.loginbutton')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className='mr-auto p-3'>
                  <Link to='/register'>{t('Login.regbutton')}</Link>
                </div>
                <div className='p-3'>
                  <Link to={'/reset'}>{t('Login.forgotpassword')}</Link>
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withRouter(Login)
