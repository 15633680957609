import { useState, useEffect } from 'react'
import axios from 'axios'
import './UserList.css'
import MaterialTable from 'material-table'

const UsersList = () => {
  // data for the table
  const [tableData, setTableData] = useState([])
  const [loading, setloading] = useState(false)

  // Table columns
  const columns = [
    { field: 'id', title: 'ID', editable: 'never' },
    { field: 'username', title: 'მომხმარებელი' },
    { field: 'userfullname', title: 'სახელი, გვარი' },
    { field: 'address', title: 'მისამართი' },
    { field: 'country', title: 'ქვეყანა' },
    { field: 'phonenumber', title: 'ტელ. ნომერი' },
    { field: 'birthdate', title: 'დაბადების თარიღი' },
    { field: 'email', title: 'ელ. ფოსტა' },
    {
      field: 'role',
      title: 'როლი',
      lookup: { Admin: 'Admin', User: 'User' },
    },
    { field: `createdAt`, title: 'რეგისტრაციის თარიღი', type: 'date', editable: 'never' },
  ]

  // User Data
  useEffect(() => {
    const getUsersList = () => {
      axios.get('/users').then(response => {
        setloading(true)
        setTableData(response.data)
      })
    }
    getUsersList()
    setloading(false)

    return () => {
      setTableData([])
    }
  }, [])

  // Delete Users
  const deleteUser = async id => {
    await axios.delete(`/users/${id}`, {
      headers: { accessToken: localStorage.getItem('accessToken') },
    })
  }

  return (
    <>
      <div className='admin-user-list'>
        <div className='createInfoCard-container'>
          <div className='admin-information'>
            <div style={{ height: 760, width: '100%' }}>
              <MaterialTable
                title='მომხმარებლები'
                columns={columns}
                data={tableData}
                isLoading={!loading}
                editable={{
                  onRowDelete: selectedRow =>
                    new Promise(resolve => {
                      const index = selectedRow.tableData.id
                      const updatedRows = [...tableData]
                      updatedRows.splice(index, 1)
                      deleteUser(selectedRow.id)
                      setTimeout(() => {
                        setTableData(updatedRows)
                        resolve()
                      }, 500)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                      const updatedData = [...tableData]
                      updatedData[oldData.tableData.id] = newData
                      // Update Users
                      const updateRow = async id => {
                        await axios.put(
                          '/users/profileupdate',
                          {
                            id: id,
                            enteredUsername: newData.username,
                            enteredUserFullName: newData.userfullname,
                            enteredCountry: newData.country,
                            enteredAddress: newData.address,
                            enteredBirthdate: newData.birthdate,
                            enteredPhoneNumber: newData.phonenumber,
                            enteredEmail: newData.email,
                            enteredRole: newData.role,
                          },
                          {
                            headers: { accessToken: localStorage.getItem('accessToken') },
                          }
                        )
                      }
                      updateRow(oldData.id)
                      setTimeout(() => {
                        setTableData(updatedData)
                        resolve()
                      }, 500)
                    }),
                }}
                options={{
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  exportButton: true,
                  columnsButton: true,
                  pageSizeOptions: [5, 10],
                  paginationType: 'stepped',
                  sorting: true,
                  filtering: true,
                  paging: true,
                  showFirstLastPageButtons: true,
                  paginationPosition: 'bottom',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersList
