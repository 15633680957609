import React, { useState, useEffect } from "react";
import axios from "axios";

const PostCounter = () => {
  const [count, setCount] = useState(0);

  // Post Data
  useEffect(() => {
    axios.get("/posts").then((response) => {
      setCount(response.data.length);
    });
    return () => {
      setCount(0);
    }
  }, []);

  return <div>{count}</div>;
};

export default PostCounter;
