/* eslint-disable jsx-a11y/iframe-has-title */
import HeroComponent from '../../components/HeroComponent/HeroComponent'
import { Container } from 'react-bootstrap'
import './Contact.css'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const Contact = () => {
  const { t } = useTranslation()
  return (
    <>
     <Helmet>
        <meta charSet='utf-8' />
        <title>{t('homepage.contactustitle')}</title>
        <link rel='canonical' href='http://moambe.org.ge/contact' />
        <meta
          name='moambe, contact, მოამბე, კონტაქტი'
          description='moambe, contact, მოამბე, კონტაქტი'
          charSet='utf-8'
        />
      </Helmet>
      <HeroComponent />
      <div className='homepage-container'>
        <Container style={{ marginTop: '50px' }}>
          <div className='archive-title'>
            <div className='border-contact-home' />
            <h4>{t('homepage.contactustitle')}</h4>
            <div className='border-contact-home' />
          </div>
          <div className='contact-wrapper'>
            <div className='google-maps'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2978.806063028457!2d44.78883101529648!3d41.70312218432524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40440cdb7a045595%3A0x9b339ade18ee471!2sGeorgian%20National%20Academy%20of%20Sciences!5e0!3m2!1sen!2sge!4v1638271514874!5m2!1sen!2sge'
                width='600'
                height='450'
                style={{ border: '0' }}
                allowFullScreen=''
                loading='lazy'></iframe>
            </div>
            {/* Left contact page */}
            <div className='contact-info'>
              <div className='direct-contact-container'>
                <ul className='contact-list'>
                  <li className='contact-text place' style={{ marginBottom: '10px' }}>
                    <span>{t('contactpage.gnas')}</span>
                  </li>
                  {t('language') === 'en' ? (
                    <>
                      <li className='list-item'>
                        <span className='contact-text place'>{t('contactpage.addressone')},</span>
                      </li>
                      <li className='list-item'>
                        <span className='contact-text place'>{t('contactpage.addresstwo')}</span>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className='list-item'>
                        <span className='contact-text place'>{t('contactpage.addresstwo')}</span>
                      </li>
                      <li className='list-item'>
                        <span className='contact-text place'>{t('contactpage.addressone')}</span>
                      </li>
                    </>
                  )}
                  <li className='list-item'>
                    <span className='contact-text phone'>
                      <a href='tel:1-212-555-5555' title='Give me a call'>
                        {t('contactpage.phone')}
                      </a>
                    </span>
                  </li>
                  <div className='contact-text' style={{ marginTop: '15px', marginBottom: '5px' }}>
                    {t('contactpage.emailtitle')}:
                  </div>
                  <li className='list-item'>
                    <span className='contact-text gmail'>
                      <a href='mailto:bulletin@science.org.ge' title='Send me an email'>
                        {t('contactpage.email')}
                      </a>
                    </span>
                  </li>
                  <li className='list-item'>
                    <span className='contact-text gmail'>
                      <a href='mailto:bull.gnas@gmail.ge' title='Send me an email'>
                        {t('contactpage.emailTwo')}
                      </a>
                    </span>
                  </li>
                </ul>
                <hr />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Contact
