import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './UpdateCardInfo.css'

import { Row, Col, Button, Form, Container } from 'react-bootstrap'

const UpdateCardInfo = props => {
  let { id } = useParams()
  const [postObject, setPostObject] = useState({})
  const [enteredTitle, setEnteredTitle] = useState('')
  const [enteredTitleEng, setEnteredTitleEng] = useState('')
  const [enteredDesc, setEnteredDesc] = useState('')
  const [enteredDescEng, setEnteredDescEng] = useState('')
  const [enteredFullDesc, setEnteredFullDesc] = useState('')
  const [enteredFullDescEng, setEnteredFullDescEng] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    axios.get(`/posts/byId/${id}`).then(response => {
      setPostObject(response.data)
    })

    return () => {
      setPostObject({})
    }
  }, [id])

  const editPost = e => {
    if (enteredTitle !== '') {
      let newTitle = enteredTitle
      axios.put(
        '/posts/title',
        { newTitle: newTitle, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
    if (enteredTitleEng !== '') {
      let newTitleEng = enteredTitleEng
      axios.put(
        '/posts/titleeng',
        { newTitleEng: newTitleEng, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
    if (enteredDesc !== '') {
      let newDesc = enteredDesc
      axios.put(
        '/posts/description',
        { newDesc: newDesc, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
    if (enteredDescEng !== '') {
      let newDescEng = enteredDescEng
      axios.put(
        '/posts/descriptionEng',
        { newDescEng: newDescEng, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
    if (enteredFullDesc !== '') {
      let newFullDesc = enteredFullDesc
      axios.put(
        '/posts/fullDescriptionGeo',
        { newFullDesc: newFullDesc, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
    if (enteredFullDescEng !== '') {
      let newFullDescEng = enteredFullDescEng
      axios.put(
        '/posts/fullDescriptionEng',
        { newFullDescEng: newFullDescEng, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
    if (imageUrl !== '') {
      let newImageUrl = imageUrl
      axios.put(
        '/posts/imageUrl',
        { newImageUrl: newImageUrl, id: id },
        {
          headers: { accessToken: localStorage.getItem('accessToken') },
        }
      )
    }
  }

  return (
    <>
      <Container>
        <div className='update-post-page full-post-page'>
          <Row className='full-post-page-row'>
            <Col className='post-image-column'>
              <img src={postObject.imageUrl} alt='post-img' />
            </Col>
          </Row>
          <>
            <Form onSubmit={editPost}>
              <Form.Group id='inputCreatePost'>
                <Form.Label>დასახელება:</Form.Label>
                <Form.Control
                  value={enteredTitle}
                  onChange={e => setEnteredTitle(e.target.value)}
                  type='text'
                  name='title'
                  placeholder={postObject.title}
                />
              </Form.Group>

              <Form.Group id='inputCreatePost'>
                <Form.Label>ინგლისური დასახელება:</Form.Label>
                <Form.Control
                  value={enteredTitleEng}
                  onChange={e => setEnteredTitleEng(e.target.value)}
                  type='text'
                  name='titleEng'
                  placeholder={postObject.titleEng}
                />
              </Form.Group>

              <Form.Group id='inputCreatePost'>
                <Form.Label>მოკლე აღწერა ქართ:</Form.Label>
                <Form.Control
                  value={enteredDesc}
                  onChange={e => setEnteredDesc(e.target.value)}
                  type='text'
                  placeholder={postObject.description}
                />
              </Form.Group>

              <Form.Group id='inputCreatePost'>
                <Form.Label>მოკლე აღწერა ინგ:</Form.Label>
                <Form.Control
                  value={enteredDescEng}
                  onChange={e => setEnteredDescEng(e.target.value)}
                  type='text'
                  placeholder={postObject.descriptionEng}
                />
              </Form.Group>

              <Form.Group id='inputCreatePost'>
                <Form.Label>სრული აღწერა ქართ:</Form.Label>
                <Form.Control
                  value={enteredFullDesc}
                  onChange={e => setEnteredFullDesc(e.target.value)}
                  type='text'
                  as='textarea'
                  placeholder={postObject.fullDescriptionGeo}
                />
              </Form.Group>

              <Form.Group id='inputCreatePost'>
                <Form.Label>სრული აღწერა ინგ:</Form.Label>
                <Form.Control
                  value={enteredFullDescEng}
                  onChange={e => setEnteredFullDescEng(e.target.value)}
                  type='text'
                  as='textarea'
                  placeholder={postObject.fullDescriptionEng}></Form.Control>
              </Form.Group>

              <Form.Group id='inputCreatePost'>
                <Form.Label>სურათი:</Form.Label>
                <Form.Control
                  value={imageUrl}
                  onChange={e => setImageUrl(e.target.value)}
                  type='text'
                  placeholder={postObject.imageUrl}
                />
              </Form.Group>
              <Button type='submit'>განახლება</Button>
            </Form>
          </>
        </div>
      </Container>
    </>
  )
}

export default UpdateCardInfo
