/* eslint-disable no-useless-rename */
import React from "react";
import { Route } from "react-router-dom";
import NoMatch from "../Pages/NoMatch/NoMatch";
import UserNav from "../Pages/User/UserNav";

const UserRoute = ({ isAuth: isAuth, isUser: isUser, component: Component, ...rest }) => {
  if (isUser === "User" && isAuth) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <UserNav />
              <div className='dashboard-component'>
                <Component />
              </div>
            </>
          );
        }}
      />
    );
  } else {
    return <NoMatch />;
  }
};

export default UserRoute;
