import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';  
import './App.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from './helpers/AuthContext';
import { NavContext } from './helpers/NavContext';

// Routes
import ProtectedRoute from './helpers/ProtectedRoute';
import AdminRoute from './helpers/AdminRoute';
import UserRoute from './helpers/UserRoute';

// Components
import Navbar from './components/NavBar/Navbar';
import News from './components/Information/News';
import UsersList from './components/UsersList/UsersList';
import CreateCardInfo from './components/createCardInfo/CreateCardInfo';
import Profile from './components/Profile/Profile';
import UpdateCardInfo from './components/UpdateCardInfo/UpdateCardInfo';
import Footer from './components/Footer/Footer';
import IndexList from './components/IndexList/IndexList';

// Pages
import Register from './Pages/RegistrationPage/Register';
import Login from './Pages/LoginPage/Login';
import HomePage from './Pages/Homepage/HomePage';
import AboutUs from './Pages/About/AboutUs';
import AdminPanel from './Pages/Admin/AdminPanel';
import Submitions from './components/Submitions/Submition';
import Guide from './Pages/Guide/Guide';
import Indexing from './Pages/Indexing/Indexing';
import Contact from './Pages/Contact/Contact';
import Submition from './Pages/Submitions/Submitions';
import Archive from './Pages/Archive/Archive';
import AdminArchive from './components/AdminArchive/AdminArchive';
import UserSubmition from './components/UserSubmition/UserSubmition';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import EnterEmail from './components/EnterEmail/EnterEmail';

function App() {
  const [open, setOpen] = useState(false);
  const [isAdminPath, setIsAdminPath] = useState(false);
  const [authState, setAuthState] = useState({
    id: 0,
    username: '',
    role: '',
    status: false,
  });
  const [userUsername, setUserUsername] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFullname, setUserFullName] = useState('');
  const [userCountry, setUserCountry] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userBirthDate, setUserBirthDate] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState(0);

  localStorage.setItem('username', userUsername);
  localStorage.setItem('email', userEmail);

  const isAdminPanel = () => {
    if (window.location.pathname.includes('/admin') || window.location.pathname.includes('/user')) {
      setIsAdminPath(true);
    } else {
      setIsAdminPath(false);
    }
  };

  useEffect(() => {
    async function getUserInfo (){
      await axios
        .get('/users/validToken', {
          headers: {
            accessToken: localStorage.getItem('accessToken'),
          },
        })
        .then((response) => {
          if (response.data.error) {
            setAuthState(false);
          } else {
            setAuthState(true);
            setUserRole(response.data.role);
            setUserUsername(response.data.username);
            setUserEmail(response.data.email);
            setUserFullName(response.data.userfullname);
            setUserCountry(response.data.country);
            setUserAddress(response.data.address);
            setUserPhoneNumber(response.data.phonenumber);
            setUserBirthDate(response.data.birthdate);
            setUserId(response.data.id);
          }
        });
    }
      getUserInfo()
      isAdminPanel();
});

  return (
    <div className='App'>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <div className='app-children'>
          <NavContext.Provider value={{ open, setOpen }}>
            {isAdminPath ? null : <Navbar userRole={userRole} userUsername={userUsername} />}
          </NavContext.Provider>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/submit' component={Submition} />
            <Route exact path='/guide' component={Guide} />
            <Route exact path='/archive' component={Archive} />
            <Route exact path='/about' component={AboutUs} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/index' component={Indexing} />
            <Route path='/news/:id' component={News} />
            <Route path='/resetpassword' component={ResetPassword} />
            <Route path='/reset' component={EnterEmail} />
            <ProtectedRoute exact path='/login' component={Login} isAuth={!authState} />
            <ProtectedRoute exact path='/register' component={Register} isAuth={!authState} />
            {/* User Routing */}
            <UserRoute
              path='/user/myprofile'
              isAuth={authState}
              isUser={userRole}
              component={(props) => (
                <Profile
                  {...props} userId={userId}
                  user={userUsername}
                  fullname={userFullname}
                  email={userEmail}
                  userCountry={userCountry}
                  userAddress={userAddress}
                  userPhoneNumber={userPhoneNumber}
                  userBirthDate={userBirthDate}
                />
              )}
            />
            <UserRoute isAuth={authState} isUser={userRole} path='/user/submitions' component={(props) => (
              <UserSubmition />
            ) } />
            {/* Admin Routing */}
            <AdminRoute 
              path='/admin/myprofile'
              isAuth={authState}
              isAdmin={userRole}
              component={(props) => (
                <Profile 
                  {...props} userId={userId}
                  user={userUsername}
                  fullname={userFullname}
                  email={userEmail}
                  userCountry={userCountry}
                  userAddress={userAddress}
                  userPhoneNumber={userPhoneNumber}
                  userBirthDate={userBirthDate}
                />
              )}
            />
            <AdminRoute exact path='/admin/dashboard' component={AdminPanel} isAuth={authState} isAdmin={userRole} />
            <AdminRoute exact path='/admin/posts' component={CreateCardInfo} isAuth={authState} isAdmin={userRole} />
            <AdminRoute exact path='/admin/index' component={IndexList} isAuth={authState} isAdmin={userRole} />
            <AdminRoute
              path='/admin/updatecardinfo/:id'
              component={UpdateCardInfo}
              isAuth={authState}
              isAdmin={userRole}
            />
            <AdminRoute exact path='/admin/submitions' component={Submitions} isAuth={authState} isAdmin={userRole} />
            <AdminRoute exact path='/admin/users' component={UsersList} isAuth={authState} isAdmin={userRole} />
            <AdminRoute exact path='/admin/archive' component={AdminArchive} isAuth={authState} isAdmin={userRole} />
          </Switch>
          </div>
        </Router>
      </AuthContext.Provider>
      {isAdminPath ? null : <Footer />}
    </div>
  );
}

export default App;
