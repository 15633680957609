import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { countries, countriesGe } from '../../Pages/RegistrationPage/Data'
import { useTranslation } from 'react-i18next'
import './Profile.css'
import { Form } from 'react-bootstrap'

const Profile = props => {
  const { t } = useTranslation()
  const [enteredUserFullname, setEnteredUserFullname] = useState('')
  const [enteredEmail, setEnteredEmail] = useState('')
  const [enteredCountry, setEnteredCountry] = useState(props.userCountry)
  const [enteredBirthDate, setEnteredBirthDate] = useState('')
  const [enteredAddress, setEnteredAddress] = useState('')
  const [enteredPassword, setEnteredPassword] = useState('')
  const [enteredRePassword, setEnteredRePassword] = useState('')

  const editUser = e => {
    e.preventDefault()
    if (enteredUserFullname !== '') {
      let newUserFullname = enteredUserFullname
      const requestChangeName = async () => {
        await axios.put(
          '/users/userfullname',
          { newUserFullname: newUserFullname, id: props.userId },
          {
            headers: { accessToken: localStorage.getItem('accessToken') },
          }
        )
      }
      requestChangeName()
    }
    if (enteredEmail !== '') {
      let newEmail = enteredEmail
      const requestChangeEmail = async () => {
        await axios.put(
          '/users/useremail',
          { newEmail: newEmail, id: props.userId },
          {
            headers: { accessToken: localStorage.getItem('accessToken') },
          }
        )
      }
      requestChangeEmail()
    }
    if (enteredCountry !== '') {
      let newCountry = enteredCountry
      const requestChangeCountry = async () => {
        await axios.put(
          '/users/usercountry',
          { newCountry: newCountry, id: props.userId },
          {
            headers: { accessToken: localStorage.getItem('accessToken') },
          }
        )
      }
      requestChangeCountry()
    }
    if (enteredBirthDate !== '') {
      let newBirthDate = enteredBirthDate
      const requestChangeBirthDate = async () => {
        await axios.put(
          '/users/userbirthdate',
          { newBirthDate: newBirthDate, id: props.userId },
          {
            headers: { accessToken: localStorage.getItem('accessToken') },
          }
        )
      }
      requestChangeBirthDate()
    }
    if (enteredAddress !== '') {
      let newAddress = enteredAddress
      const requestChangeAddress = async () => {
        await axios.put(
          '/users/useraddress',
          { newAddress: newAddress, id: props.userId },
          {
            headers: { accessToken: localStorage.getItem('accessToken') },
          }
        )
      }
      requestChangeAddress()
    }
    if (enteredPassword !== '' && enteredRePassword !== '') {
      let password = enteredPassword
      const requestChangeAddress = async () => {
        await axios.patch('/users/userpassword', { password: password, id: props.userId })
      }
      requestChangeAddress()
    }
  }

  return (
    <div className='container'>
      <div className='admin-panel'>
        <div className='my-profile-box'>
          <Alert severity='info'>
            მონაცემების შეცვლის შემთხვევაში ინფორმაცია განახლდება თქვენს ანგარიშზე ხელახლა შესვლით
          </Alert>
          <Form onSubmit={editUser}>
            <Form.Group id='inputCreatePost'>
              <Form.Label>მომხმარებელი:</Form.Label>
              <Form.Control disabled type='text' placeholder={props.user} />

              <Form.Label>სახელი, გვარი:</Form.Label>
              <Form.Control
                value={enteredUserFullname}
                onChange={e => setEnteredUserFullname(e.target.value)}
                type='text'
                name='userfullname'
                placeholder={props.fullname}
              />

              <Form.Label>ელ. ფოსტა:</Form.Label>
              <Form.Control
                value={enteredEmail}
                onChange={e => setEnteredEmail(e.target.value)}
                type='text'
                placeholder={props.email}
              />

              <Form.Label>{t('Registration.country')}</Form.Label>
              <Form.Control
                as='select'
                name='country'
                value={enteredCountry}
                onChange={e => setEnteredCountry(e.target.value)}
                type='text'>
                {t('Registration.language') !== 'ka' ? (
                  <>
                    {countries.map(country => {
                      return <option key={country.code}>{country.name}</option>
                    })}
                  </>
                ) : (
                  <>
                    {countriesGe.map(country => {
                      return <option key={country.code}>{country.name}</option>
                    })}
                  </>
                )}
              </Form.Control>

              <Form.Label>{t('Registration.birthdate')}</Form.Label>
              <p>{props.userBirthDate}</p>
              <Form.Control
                type='date'
                placeholder={props.userBirthDate}
                aria-describedby='inputGroupPrepend'
                name='birthdate'
                value={enteredBirthDate}
                onChange={e => setEnteredBirthDate(e.target.value)}
              />
            </Form.Group>

            <Form.Label>{t('Registration.address')}</Form.Label>
            <Form.Control
              placeholder={props.userAddress}
              value={enteredAddress}
              onChange={e => setEnteredAddress(e.target.value)}
              type='text'
              name='address'
            />

            <Form.Label>{t('Registration.password')}</Form.Label>
            <Form.Control
              placeholder={t('Registration.password')}
              value={enteredPassword}
              onChange={e => setEnteredPassword(e.target.value)}
              type='password'
              name='password'
            />

            <Form.Label>{t('Registration.repassword')}</Form.Label>
            <Form.Control
              placeholder={t('Registration.repassword')}
              value={enteredRePassword}
              onChange={e => setEnteredRePassword(e.target.value)}
              type='password'
              name='repeatpassword'
            />

            <Button variant='contained' type='submit' style={{ marginTop: '20px' }}>
              განახლება
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Profile)
