import { useCallback, useState, useEffect } from 'react'
import ImageViewer from 'react-simple-image-viewer'

const TopImages = ({ images }) => {
  const openImageViewer = useCallback(index => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [imageViewerImages, setImageViewerImages] = useState([])

  useEffect(() => {
    const tmp = []

    for (let i = 0; i < images.length; i++) {
      if (images[i].includes('kda.jpeg')) continue
      tmp.push(`http://moambe.org.ge/${images[i]}`)
    }

    setImageViewerImages(tmp)

    return () => {
      setImageViewerImages([])
    }
  }, [images])

  return (
    <>
      {imageViewerImages.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width='300'
          height='440'
          key={index}
          style={{ margin: '2px' }}
          alt='tituli'
        />
      ))}
      {isViewerOpen && (
        <ImageViewer
          src={imageViewerImages}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}

export default TopImages
