import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './News.css'

import { Row, Col } from 'react-bootstrap'
import HeroComponent from '../HeroComponent/HeroComponent'

const News = () => {
  let { id } = useParams()
  const [postObject, setPostObject] = useState({})

  useEffect(() => {
    const getPosts = async () => {
      await axios.get(`/posts/byId/${id}`).then(response => {
        setPostObject(response.data)
      })
    }
    getPosts()

    return () => {
      setPostObject({})
    }
  }, [id])

  return (
    <>
      <HeroComponent />
      <div className='homepage-container'>
        <div className='full-post-page'>
          <Row className='full-post-page-row'>
            <Col className='post-image-column'>
              <img src={postObject.imageUrl} alt='post-img' />
            </Col>
            <div className='post-title'>
              <h1>{postObject.title}</h1>
              {/* <h1>{postObject.titleEng}</h1> */}
            </div>
            <div className='post-fullDescription'>
              <p>{postObject.fullDescriptionGeo}</p>
              {/* <p>{postObject.fullDescriptionEng}</p> */}
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

export default News
