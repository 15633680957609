import { useEffect, useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { heads, heads_en } from '../Data'
import blankProfile from '../../../assets/images/AboutUs/blank-profile.png'
import { useTranslation } from 'react-i18next'

const Head = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])

  useEffect(() => {
    setData(t('language') === 'ka' ? heads : heads_en)

    return () => {
      setData([])
    }
  }, [t])

  return (
    <>
      <Row style={{ marginTop: '50px' }}>
        {data.map(head => {
          return (
            <Card key={head.name} style={{ width: '14rem', margin: '10px' }}>
              {head.img === '' ? (
                <Card.Img variant='top' src={blankProfile} />
              ) : (
                <Card.Img variant='top' src={head.img} style={{height: '222px', objectFit: 'cover'}} />
              )}
              <Card.Body>
                <p
                  style={{
                    fontSize: '14px',
                    height: '20px',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                    fontWeight: 'bold',
                  }}>
                  {head.name}
                </p>
                <p
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    fontSize: '12px',
                    fontStyle: 'italic',
                  }}>
                  {head.title}
                </p>
              </Card.Body>
            </Card>
          )
        })}
      </Row>
    </>
  )
}

export default Head
