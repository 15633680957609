import './AboutUs.css'
import { Container } from '@material-ui/core'
import HeroComponent from '../../components/HeroComponent/HeroComponent'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Editorial from './Components/Editorial'
import Head from './Components/Head'
import Colleague from './Components/Colleague'
import Council from './Components/Council'
import Typography from '@mui/material/Typography'

const AboutUs = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{t('Aboutus.title')}</title>
        <link rel='canonical' href='http://moambe.org.ge/about' />
        <meta
          name='moambe, about us, მოამბე, ჩვენ შესახებ'
          description='moambe, about us, მოამბე, ჩვენ შესახებ'
          charSet='utf-8'
        />
      </Helmet>
      <HeroComponent />
      <div className='about-us'>
        <Container>
          <div className='about-us-content'>
            <div className='archive-title' style={{ marginTop: '50px' }}>
              <div className='border-contact-home' />
              <h4>{t('Aboutus.title')}</h4>
              <div className='border-contact-home' />
            </div>
            <div className='about-us-content-main'>
              {t('Aboutus.firstText') === '' ? null : (
                <>
                  <Typography align='justify' style={{ textIndent: '20px', fontSize: '14px' }}>
                    {t('Aboutus.firstText')}
                  </Typography>
                </>
              )}
              <div className='about-moambe-members'>
                <Head />
                <Council />
                <Editorial />
                <Colleague />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default AboutUs
