import './Footer.css'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  const NavbarFooter = [
    { name: `${t('footer.menu.aboutUs')}`, link: '/about', className: 'link-1' },
    { name: `${t('footer.menu.guide')}`, link: '/guide', className: '' },
    { name: `${t('footer.menu.submit')}`, link: '/submit', className: '' },
    { name: `${t('footer.menu.archive')}`, link: '/archive', className: '' },
  ]
  return (
    <footer className='footer-distributed'>
      <div className='footer-left'>
        <p className='footer-links'>
          {NavbarFooter.map(item => {
            return (
              <a className={item.className} key={item.name} href={item.link}>
                {item.name}
              </a>
            )
          })}
        </p>

        <p className='footer-company-name'>{t('footer.copy')}</p>
      </div>

      <div className='footer-center'>
        <span className='weblogo-footer'>
          <div className='web-logo'></div>
        </span>
      </div>
      <div className='footer-right'>
        <div>
          {t('language') === 'en' ? (
            <>
              <span>{t('contactpage.addressone')}</span>
              <br />
              <span>{t('contactpage.addresstwo')}</span>
            </>
          ) : (
            <>
              <span>{t('contactpage.addresstwo')}</span>
              <br />
              <span>{t('contactpage.addressone')}</span>
            </>
          )}
          <p>{/* <span>{t('footer.location.address')}</span> {t('footer.location.city')} */}</p>
        </div>

        <div>
          <p>(+995 32) 299-75-93 </p>
        </div>

        <div>
          <p>
            <a href='mailto:bulletin@science.org.ge'>bulletin@science.org.ge</a>
          </p>
        </div>
        <div>
          <p>
            <a href='mailto:bull.gnas@gmail.com'>bull.gnas@gmail.com</a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
