import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import './CreateCardInfo.css'

import { Row, Button, Col, Card, Dropdown, Container } from 'react-bootstrap'

const CreateCardInfo = () => {
  const validationSchema = yup.object().shape({
    title: yup.string().required('სათაურის დაწერა აუცილებელია'),
    titleEng: yup.string().optional('სათაურის დაწერ არ არის სავალდებულო'),
    imageUrl: yup.string().required('სურათი აუცილებელია'),
    description: yup.string().min(5).max(500).required('სავალდებულო ველი'),
    descriptionEng: yup.string().min(5).max(500).required('სავალდებულო ველი'),
    fullDescriptionGeo: yup.string().min(10).max(2000).required('სავალდებულო ველი'),
    fullDescriptionEng: yup.string().min(10).max(2000).required('სავალდებულო ველი'),
  })

  const initialValues = {
    title: '',
    titleEng: '',
    imageUrl: '',
    description: '',
    descriptionEng: '',
    fullDescriptionGeo: '',
    fullDescriptionEng: '',
  }

  let history = useHistory()

  // Add post
  const onSubmit = data => {
    axios.post('/posts', data).then(response => {
      window.location.reload(true)
    })
  }

  const [listOfPosts, setListOfPosts] = useState([])

  useEffect(() => {
    const getPosts = async () => {
      await axios.get('/posts').then(response => {
        setListOfPosts(response.data)
      })
    }
    getPosts()

    return () => {
      setListOfPosts([])
    }
  }, [])

  // delete Post

  const deletePost = async id => {
    await axios.delete(`/posts/${id}`, {
      headers: { accessToken: localStorage.getItem('accessToken') },
    })
    window.location.reload(false)
  }

  return (
    <>
      <Container>
        <div className='createInfoCard-container'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}>
            <Form>
              <Row>
                <Col>
                  <label>დასახელება ქართულად</label>
                  <ErrorMessage name='title' component='span' />
                  <Field
                    autoComplete='off'
                    id='inputCreatePost'
                    name='title'
                    placeholder='Geo title'
                  />
                </Col>
                <Col>
                  <label>დასახელება ინგლისურად</label>
                  <ErrorMessage name='titleEng' component='span' />
                  <Field
                    autoComplete='off'
                    id='inputCreatePost'
                    name='titleEng'
                    placeholder='Eng Title'
                  />
                </Col>
              </Row>
              <label>სურათის ლინკი</label>
              <ErrorMessage name='imageUrl' component='span' />
              <Field
                autoComplete='off'
                id='inputCreatePost'
                name='imageUrl'
                placeholder='image URL'
              />
              <Row>
                <Col>
                  <label>მოკლე აღწერა ქართულად</label>
                  <ErrorMessage name='description' component='span' />
                  <Field
                    autoComplete='off'
                    id='inputCreatePost'
                    name='description'
                    placeholder='Geo Description Here'
                  />
                </Col>
                <Col>
                  <label>მოკლე აღწერა ინგლისურად</label>
                  <ErrorMessage name='descriptionEng' component='span' />
                  <Field
                    autoComplete='off'
                    id='inputCreatePost'
                    name='descriptionEng'
                    placeholder='Eng Description Here'
                  />
                </Col>
              </Row>
              <label>სრული აღწერა ქართულად</label>
              <ErrorMessage name='fullDescriptionGeo' component='span' />
              <Field
                as='textarea'
                autoComplete='off'
                id='inputCreatePost'
                name='fullDescriptionGeo'
                placeholder='Geo Full Description Here'
              />
              <label>სრული აღწერა ინგლისურად</label>
              <ErrorMessage name='fullDescriptionEng' component='span' />
              <Field
                as='textarea'
                autoComplete='off'
                id='inputCreatePost'
                name='fullDescriptionEng'
                placeholder='Eng Full Description Here'
              />
              <Button variant='primary' type='submit'>
                გამოქვეყნება
              </Button>
            </Form>
          </Formik>
          <div className='posted-news'>
            <div className='edit-news-cards'>
              {listOfPosts.map((value, key) => {
                return (
                  <Col key={key}>
                    <div className='admin-information'>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant='top' src={value.imageUrl} alt='card-img' />
                        <Card.Body>
                          <Card.Title>{value.title}</Card.Title>
                          <Card.Text>{value.description}</Card.Text>
                          <div className='card-body-row'>
                            <Button
                              variant='primary'
                              onClick={() => {
                                history.push(`/News/${value.id}`)
                              }}>
                              მეტის ნახვა
                            </Button>

                            <Dropdown className='dropdown-edit-btn'>
                              <Dropdown.Toggle id='dropdown-basic-button' />
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    history.push(`/admin/updatecardinfo/${value.id}`)
                                  }}>
                                  ჩასწორება
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() => {
                                    deletePost(value.id)
                                  }}>
                                  წაშლა
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                )
              })}
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CreateCardInfo
