import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from '../../helpers/AuthContext'
import { VscAccount } from 'react-icons/vsc'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'
import { NavContext } from '../../helpers/NavContext'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import cookies from 'js-cookie'
import WebLogo from '../webLogo/WebLogo'
import SearchModal from '../SearchModal/SearchModal'
import gnasLogo from '../../../src/assets/images/gnasLogo.jpg'

const NavbarLinks = props => {
  const { t } = useTranslation()

  const navList = [
    {
      name: t('Navbar.home'),
      link: '/',
    },
    {
      name: t('Navbar.aboutUs'),
      link: '/about',
    },
    {
      name: t('Navbar.index'),
      link: '/index',
    },
    {
      name: t('Navbar.guide'),
      link: '/guide',
    },
    {
      name: t('Navbar.submit'),
      link: '/submit',
    },
    {
      name: t('Navbar.archive'),
      link: '/archive',
    },
    {
      name: t('Navbar.contact'),
      link: '/contact',
    },
  ]

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ka',
      name: 'ქართული',
      country_code: 'ge',
    },
  ]

  const currentLangCode = cookies.get('i18next') || 'en'
  let currentLanguage = languages.find(l => l.code === currentLangCode)

  const [lang, setLang] = useState(currentLanguage)

  const { open, setOpen } = useContext(NavContext)
  const { authState, setAuthState } = useContext(AuthContext)

  let history = useHistory()
  const logout = () => {
    localStorage.removeItem('accessToken')
    setAuthState(false)
    history.push('/')
  }

  let username = props.userUsername

  const myProfileButton = () => {
    return (
      <>
        <VscAccount style={{ color: 'rgb(0, 35, 54)' }} /> <span>{username}</span>
      </>
    )
  }

  return (
    <div className='navbar-links Nav-bar'>
      <WebLogo />
      <div className='gnasLogo_main'>
        <img src={gnasLogo} alt='gnas' />
      </div>
      <ul className='Nav-list'>
        {navList.map((e, i) => (
          <li key={i}>
            <Link
              className={e.link === window.location.pathname ? 'active-background' : null}
              to={e.link}
              onClick={() => setOpen(!open)}
            >
              <span>{e.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <SearchModal />
      <ul className='Nav-login'>
        {!authState && (
          <>
            <li>
              <Link to='/login' className='auth' onClick={() => setOpen(!open)}>
                <span>
                  <VscAccount style={{ color: 'rgb(0, 35, 54)' }} /> {t('Navbar.authButton')}
                </span>
              </Link>
            </li>
          </>
        )}
        {authState && (
          <>
            <ul className='Nav-list content'>
              <li>
                <span>
                  <DropdownButton
                    className='drop-p'
                    menualign='left'
                    id='dropdown-basic-button'
                    title={myProfileButton()}
                  >
                    {props.role === 'Admin' && (
                      <Dropdown.Item href='/admin/dashboard'>
                        {t('Navbar.adminPanel')}
                      </Dropdown.Item>
                    )}
                    {props.role === 'User' && (
                      <Dropdown.Item href='/user/myprofile' onClick={() => setOpen(!open)}>
                        {t('Navbar.profile')}
                      </Dropdown.Item>
                    )}
                    <div className='logout-span' onClick={() => setOpen(!open)}>
                      <Dropdown.Item onClick={logout} href='/'>
                        {t('Navbar.logout')}
                      </Dropdown.Item>
                    </div>
                  </DropdownButton>
                </span>
              </li>
            </ul>
          </>
        )}
        <ul className='Nav-list content'>
          <li>
            <span>
              <Dropdown className='drop-p' menualign='left' id='dropdown-basic-button'>
                <DropdownButton
                  className=' drop-p'
                  menualign='left'
                  id='dropdown-basic-button'
                  title={lang.name}
                >
                  {languages.map(({ code, name, country_code }) => (
                    <Dropdown.Item
                      key={country_code}
                      onClick={() => {
                        i18n.changeLanguage(code)
                        setLang(languages.find(l => l.code === code))
                      }}
                    >
                      <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
                      {name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Dropdown>
            </span>
          </li>
        </ul>
      </ul>
    </div>
  )
}

export default NavbarLinks
