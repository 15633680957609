import { useEffect, useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { council, council_en } from '../Data'
import blankProfile from '../../../assets/images/AboutUs/blank-profile.png'
import { useTranslation } from 'react-i18next'

const Council = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])

  useEffect(() => {
    setData(t('language') === 'ka' ? council : council_en)

    return () => {
      setData([])
    }
  }, [t])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '50px',
        }}>
        {data.length >= 1 ? <h5>{t('Aboutus.councilboard')}</h5> : null}
      </div>
      <Row style={{ marginTop: '50px' }}>
        {data.map(council => {
          return (
            <Card key={council.name} style={{ width: '14rem', height: '23rem', margin: '10px' }}>
              {council.img === '' ? (
                <Card.Img
                  variant='top'
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                  src={blankProfile}
                />
              ) : (
                <Card.Img
                  variant='top'
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                  src={council.img}
                />
              )}
              <Card.Body
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'cneter',
                  textAlign: 'center',
                  height: '100%',
                }}>
                <p
                  style={{
                    fontSize: '13px',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                  }}>
                  {council.name}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {council.city}
                </p>
                <p
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    fontSize: '12px',
                    fontStyle: 'italic',
                  }}>
                  ({council.title})
                </p>
              </Card.Body>
            </Card>
          )
        })}
      </Row>
    </>
  )
}

export default Council
