import { useCallback, useState } from 'react'
import ImageViewer from 'react-simple-image-viewer'
import moambeCover from '../../../assets/images/AboutUs/bulletin-cover/moambe-cover.png'
import moambeCoverTwo from '../../../assets/images/AboutUs/bulletin-cover/moambe-cover2.png'
import oldCover from '../../../assets/images/old-covers/old-cover.jpeg'
import oldEditors from '../../../assets/images/old-covers/old-editors.jpeg'
import oldEditorsOne from '../../../assets/images/old-covers/old-editors-1.jpeg'
import oldEditorsTwo from '../../../assets/images/old-covers/old-editors-2.jpeg'

const oldCovers = [moambeCover, moambeCoverTwo, oldCover, oldEditors, oldEditorsOne, oldEditorsTwo]

const OldCovers = () => {
  const openImageViewer = useCallback(index => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  return (
    <>
      {oldCovers.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width='200'
          height='300'
          key={index}
          alt='tituli'
        />
      ))}
      {isViewerOpen && (
        <ImageViewer
          src={oldCovers}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  )
}

export default OldCovers
