import React, { useState, useEffect } from "react";
import axios from "axios";

const SubmitionCounter = () => {
  const [count, setCount] = useState(0);

  // User Data
  useEffect(() => {
    const SubmitionCount = async () => {
      await axios.get("/submitions").then((response) => {
        setCount(response.data.length);
      });
    }
    SubmitionCount();
    return () => {
      setCount(0);
    }
  }, []);

  return <div>{count}</div>;
};

export default SubmitionCounter;
