export const archived = [
    {
      yearVol: '2007 Vol.1',
      oneUrl: 'http://science.org.ge/old/moambe/vol175.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol175-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol175-3.html',
      fourUrl: 'http://science.org.ge/old/moambe/vol175-4.html',
    },
    {
      yearVol: '2008 Vol.2',
      oneUrl: 'http://science.org.ge/old/moambe/vol2-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol2-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol2-3.html',
      fourUrl: 'http://science.org.ge/old/moambe/vol2-4.html',
    },
    {
      yearVol: '2009 Vol.3',
      oneUrl: 'http://science.org.ge/old/moambe/vol3-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol3-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol3-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2010 Vol.4',
      oneUrl: 'http://science.org.ge/old/moambe/vol4-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol4-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol4-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2011 Vol.5',
      oneUrl: 'http://science.org.ge/old/moambe/vol5-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol5-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol5-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2012 Vol.6',
      oneUrl: 'http://science.org.ge/old/moambe/vol6-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol6-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol6-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2013 Vol.7',
      oneUrl: 'http://science.org.ge/old/moambe/vol7-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol7-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol7-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2014 Vol.8',
      oneUrl: 'http://science.org.ge/old/moambe/vol8-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol8-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol8-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2015 Vol.9',
      oneUrl: 'http://science.org.ge/old/moambe/vol9-1.html',
      twoUrl: 'http://science.org.ge/old/moambe/vol9-2.html',
      threeUrl: 'http://science.org.ge/old/moambe/vol9-3.html',
      fourUrl: '',
    },
    {
      yearVol: '2016 Vol.10',
      oneUrl: 'http://science.org.ge/bnas/vol-10-1.html',
      twoUrl: 'http://science.org.ge/bnas/vol-10-2.html',
      threeUrl: 'http://science.org.ge/bnas/vol-10-3.html',
      fourUrl: 'http://science.org.ge/bnas/vol-10-4.html',
    },
    {
      yearVol: '2017 Vol.11',
      oneUrl: 'http://science.org.ge/bnas/vol-11-1.html',
      twoUrl: 'http://science.org.ge/bnas/vol-11-2.html',
      threeUrl: 'http://science.org.ge/bnas/vol-11-3.html',
      fourUrl: 'http://science.org.ge/bnas/vol-11-4.html',
    },
    {
      yearVol: '2018 Vol.12',
      oneUrl: 'http://science.org.ge/bnas/vol-12-1.html',
      twoUrl: 'http://science.org.ge/bnas/vol-12-2.html',
      threeUrl: 'http://science.org.ge/bnas/vol-12-3.html',
      fourUrl: 'http://science.org.ge/bnas/vol-12-4.html',
    },
    {
      yearVol: '2019 Vol.13',
      oneUrl: 'http://science.org.ge/bnas/vol-13-1.html',
      twoUrl: 'http://science.org.ge/bnas/vol-13-2.html',
      threeUrl: 'http://science.org.ge/bnas/vol-13-3.html',
      fourUrl: 'http://science.org.ge/bnas/vol-13-4.html',
    },
    {
      yearVol: '2020 Vol.14',
      oneUrl: 'http://science.org.ge/bnas/vol-14-1.html',
      twoUrl: 'http://science.org.ge/bnas/vol-14-2.html',
      threeUrl: 'http://science.org.ge/bnas/vol-14-3.html',
      fourUrl: 'http://science.org.ge/bnas/vol-14-4.html',
    },
    {
      yearVol: '2021 Vol.15',
      oneUrl: 'http://science.org.ge/bnas/vol-15-1.html',
      twoUrl: 'http://science.org.ge/bnas/vol-15-2.html',
      threeUrl: 'http://science.org.ge/bnas/vol-15-3.html',
      fourUrl: 'http://science.org.ge/bnas/vol-15-4.html',
    },
    {
      yearVol: '2022 Vol.16',
      oneUrl: 'http://science.org.ge/bnas/vol-16-1.html',
      twoUrl: '',
      threeUrl: '',
      fourUrl: '',
    },
  ];
  
export const archivedEarlyYears = [
    {
        yearVol: '1996',
        titleOne: 'V.153 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/pub3/spis53_1.htm',
        titleTwo: 'V.153 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/pub3/spis53_2.htm',
        titleThree: 'V.153 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/pub3/spis53_3.htm',
        titleFour: 'V.154 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/pub4/spis54_1.htm',
        titleFive: 'V.154 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/pub4/spis54_2.htm',
        titleSix: 'V.154 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/pub4/spis54_3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '1997',
        titleOne: 'V.155 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/pub5/spis55_1.htm',
        titleTwo: 'V.155 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/pub5/spis55_2.htm',
        titleThree: 'V.155 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/pub5/spis55_3.htm',
        titleFour: 'V.156 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/pub6/spis56_1.htm',
        titleFive: 'V.156 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/pub6/spis56_2.htm',
        titleSix: 'V.156 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/pub6/spis56_3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '1998',
        titleOne: 'V.157 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/pub7/spis57_1.htm',
        titleTwo: 'V.157 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/pub7/spis57_2.htm',
        titleThree: 'V.157 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/pub7/spis57_3.htm',
        titleFour: 'V.158 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/pub8/spis58_1.htm',
        titleFive: 'V.158 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/pub8/spis58_2.htm',
        titleSix: 'V.158 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/pub8/spis58_3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '1999',
        titleOne: 'V.159 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/pub9/spis59_1.htm',
        titleTwo: 'V.159 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/pub9/spis59_2.htm',
        titleThree: 'V.159 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/pub9/spis59_3.htm',
        titleFour: 'V.160 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/pub10/spis60_1.htm',
        titleFive: 'V.160 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/pub10/spis60_2.htm',
        titleSix: 'V.160 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/pub10/spis60_3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '2000',
        titleOne: 'V.161 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/pub11/spis61_1.htm',
        titleTwo: 'V.161 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/New/pub14/161_2.htm',
        titleThree: 'V.161 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/New/pub14/161_3.htm',
        titleFour: 'V.162 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/New/pub12/spis62_1.htm',
        titleFive: 'V.162 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/pub13/162-2.htm',
        titleSix: 'V.162 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/pub13/162-3.htm',
        titleSeven: 'V.162 n.4',
        sevenUrl: 'http://science.org.ge/old/moambe/pub13/162-4.htm',
      },
      {
        yearVol: '2001',
        titleOne: 'V.163 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/New/pub13/spis63_1.htm',
        titleTwo: 'V.163 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/New/pub13/spis63_2.htm',
        titleThree: 'V.163 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/New/pub13/spis63_3.htm',
        titleFour: 'V.164 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/New/pub13/164-1.htm',
        titleFive: 'V.164 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/New/pub14/164_2.htm',
        titleSix: 'V.164 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/New/pub13/164-3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '2002',
        titleOne: 'V.165 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/New/pub14/165_1.htm',
        titleTwo: 'V.165 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/New/pub14/165_2.htm',
        titleThree: 'V.165 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/New/pub14/165_3.htm',
        titleFour: 'V.166 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/New/pub15/166_1/166_1.html',
        titleFive: 'V.166 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/New/pub15/166_2/166_2.html',
        titleSix: 'V.166 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/New/pub15/166_3/166_3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '2003',
        titleOne: 'V.167 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/New/pub15/167_1/167_1.html',
        titleTwo: 'V.167 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/New/pub15/167_2/167_2.html',
        titleThree: 'V.167 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/New/pub15/167_3/167_3.htm',
        titleFour: 'V.168 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/New/pub15/168_1/168_1.html',
        titleFive: 'V.168 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/New/pub15/168_2/168_2.html',
        titleSix: 'V.168 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/New/pub15/168_3/168_3.html',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '2004',
        titleOne: 'V.169 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/New/pub15/169_1/169_1.html',
        titleTwo: 'V.169 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/New/pub15/169_2/169_2.html',
        titleThree: 'V.169 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/New/pub15/169_3/169_3.html',
        titleFour: 'V.170 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/New/pub15/170_1/170_1.html',
        titleFive: 'V.170 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/New/pub15/170_2/170_2.htm',
        titleSix: 'V.170 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/New/pub15/170_3/170_3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '2005',
        titleOne: 'V.171 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/New/pub15/171_1/171_1.htm',
        titleTwo: 'V.171 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/New/pub15/171_2/171_2.htm',
        titleThree: 'V.171 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/New/pub15/171_3/171_3.htm',
        titleFour: 'V.172 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/New/pub15/172_1/172_1.htm',
        titleFive: 'V.172 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/New/pub15/172_2/172_2.htm',
        titleSix: 'V.172 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/Summary-172-3.htm',
        titleSeven: '',
        sevenUrl: '',
      },
      {
        yearVol: '2006',
        titleOne: 'V.173 n.1',
        oneUrl: 'http://science.org.ge/old/moambe/Summary-173-1.htm',
        titleTwo: 'V.173 n.2',
        twoUrl: 'http://science.org.ge/old/moambe/173_2/Rezume-173-2.htm',
        titleThree: 'V.173 n.3',
        threeUrl: 'http://science.org.ge/old/moambe/173_3/Summuru_173-3.htm',
        titleFour: 'V.174 n.1',
        fourUrl: 'http://science.org.ge/old/moambe/174_1/summary_174_1.htm',
        titleFive: 'V.174 n.2',
        fiveUrl: 'http://science.org.ge/old/moambe/Summury_174-2.html',
        titleSix: 'V.174 n.3',
        sixUrl: 'http://science.org.ge/old/moambe/Summury_174-3.html',
        titleSeven: '',
        sevenUrl: '',
      },
]