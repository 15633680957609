import { useTranslation } from 'react-i18next';
import logoEng from '../../assets/images/webLogo/gnas-logo-eng.png';
import logoGeo from '../../assets/images/webLogo/lastlogogeo-01.png';

const WebLogoEng = () => {
  return (
    <>
      <img src={logoEng} alt='logo-eng' />
    </>
  );
};

const WebLogoGeo = () => {
  return (
    <>
      <img src={logoGeo} alt='logo-eng' />
    </>
  );
};

const WebLogo = () => {
  const { t } = useTranslation();
  return (
    <div className='web-logo'>
      <a href='/'>{`${t('webLogo.imageclass')}` === 'web-logo-eng' ? <WebLogoEng /> : <WebLogoGeo />}</a>
    </div>
  );
};

export default WebLogo;
