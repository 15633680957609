import React, { useEffect, useState } from 'react'
import { colleague, colleague_en } from '../Data'
import { Card, Row } from 'react-bootstrap'
import blankProfile from '../../../assets/images/AboutUs/blank-profile.png'
import { useTranslation } from 'react-i18next'

const Colleague = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])

  useEffect(() => {
    setData(t('language') === 'ka' ? colleague : colleague_en)

    return () => {
      setData([])
    }
  }, [t])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
        }}></div>
      <Row>
        {data.map(editorial => {
          return (
            <Card key={editorial.name} style={{ width: '14rem', height: '21rem', margin: '10px' }}>
              {editorial.img === '' ? (
                <Card.Img
                  variant='top'
                  src={blankProfile}
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                />
              ) : (
                <Card.Img
                  variant='top'
                  src={editorial.img}
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                />
              )}
              <Card.Body>
                <p
                  style={{
                    fontSize: '14px',
                    height: '20px',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                  }}>
                  {editorial.name}
                </p>
                <p
                  style={{
                    fontSize: 'small',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontStyle: 'italic',
                  }}>
                  ({editorial.title})
                </p>
              </Card.Body>
            </Card>
          )
        })}
      </Row>
    </div>
  )
}

export default Colleague
