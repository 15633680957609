import { useState } from 'react'
import axios from 'axios'
import * as React from 'react'
import Cookies from 'js-cookie'
import '../RegistrationPage/Register.css'
import { Form, Button, Container, Row, Alert, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const ResetPassword = () => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const history = useHistory()

  const id = Cookies.get('access-reset-password')

  const resetPassword = e => {
    if (password !== null) {
      e.preventDefault()
      const requestChangeAddress = async () => {
        await axios.patch('/users/userpassword', {
          id: id,
          password: password,
        })
      }
      requestChangeAddress()

      if (requestChangeAddress) {
        history.push('/login')
      }
    }
  }

  return (
    <Container>
      <Row className='register-row register-page'>
        <Col md='5' className='login-form'>
          <Form onSubmit={resetPassword}>
            <Form.Group id='inputCreatePost'>
              <Alert variant='primary'>
                <h4>{t('Login.resetpassword')}</h4>
              </Alert>
              <Form.Control
                placeholder='Password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                type='password'
                name='password'
              />
            </Form.Group>
            <Button variant='primary' type='submit' className='register-page btn'>
              {t('Login.resetpasswordbutton')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ResetPassword
