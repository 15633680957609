import { useTranslation } from 'react-i18next'
import { NavDropdown, ListGroup, Col } from 'react-bootstrap'
import '../Archive.css'

import { archived } from '../Data'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

const ArchiveForHomePage = () => {
  const { t } = useTranslation()
  const url = 'http://moambe.org.ge'
  const [newArchives, setNewArchives] = useState([])


  const sortAlgo = useCallback((arr) => {
    return Object.values(arr).sort((a, b) => (a.folder < b.folder ? 1 : -1));
  }, []);
  useEffect(() => {
    const request = async () => {
      const res = await axios.get('/archive')

    const sortedArchives = sortAlgo(res?.data[0]?.versions.files);

      setNewArchives(sortedArchives);
    }
    request()

    return () => {
      setNewArchives([])
    }
  }, [sortAlgo])

  return (
    <>
      {archived.length !== 0 ? (
        <div className='archive-page' style={{ marginTop: '50px' }}>
          <div className='container'>
            <div className='archive-wrapper'>
              <div className='archive-title' style={{ marginBottom: '50px' }}>
                <div className='border-contact-home' />
                <h4>{t('archive.title')}</h4>
                <div className='border-contact-home' />
              </div>
              <div
                style={{
                  backgroundColor: 'rgb(235, 235, 235, 0.2)',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingBottom: '20px',
                }}
              >
                <div
                  className='archived-files'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    color: '#000',
                    borderRadius: '5px',
                  }}
                >
                  {newArchives?.map((e, i) => {
                      return (
                        <Col md='2' key={i}>
                          <ListGroup>
                            <ListGroup.Item>
                              <NavDropdown title={e.folder} id='collasible-nav-dropdown'>
                                {e?.attached?.map((attached, index) => {
                                  return (
                                    <NavDropdown.Item
                                      href={url + attached.html}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      No.{index + 1}
                                    </NavDropdown.Item>
                                  )
                                })}
                              </NavDropdown>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                      )
                    })}
                </div>
                <div className='see-all'>
                  <a href='/archive'>{t('archive.showme')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ArchiveForHomePage
